import React from 'react'
import classes from './AGB.module.css'
import Footer from './Footer'

const AGB = (props) => {
  return (
  <>    
  <div className={classes.container}>
    <div className={classes.containerText}>
    <h1 className={classes.header}> {props.lang === "de" ? "Allgemeine Geschäftsbedingungen" : "General terms and conditions"} </h1>
    <ol>
      <li className={classes.headline}>{ props.lang === "de" ? "Geltungsbereich:" : "Scope:"}</li>
    </ol>
    {props.lang === "de" ?(
    <>
     <p>Fatah Mohammad erbringt Lieferungen und Leistungen im Bereich Bereich der Innenausstattung bzw. Raumausstattung. Weiters vermittelt die Fatah Mohammad Verträge betreffend Facility Management, Bauprojekten, Sanierungen und sonstigen Handwerkerleistungen.</p>
    <p>Die nachfolgenden Allgemeinen Geschäftsbedingungen (kurz „AGB“) gelten für alle Lieferungen und Leistungen die von Fatah Mohammad für den Vertragspartner (Verbraucher oder Unternehmer) erbracht werden. Im Folgenden wird die Fatah Mohammad als ”MF Infinity” und der jeweilige Vertragspartner als „Kunde“ bezeichnet.</p>
    <p>Mit Vertragsabschluss akzeptiert der Kunde diese AGB rechtsverbindlich und vollumfänglich, sodass diese zum Vertragsinhalt geworden sind. Das gilt auch für den Fall, dass ein Kunde auf seine eigenen Geschäftsbedingungen verweist. Etwaige Geschäftsbedingungen des Kunden sind ausdrücklich ausgeschlossen, wenn sie nicht ausdrücklich schriftlich durch den MF Infinity anerkannt wurden.</p>
    <p>Diese AGB gelten zudem für zukünftige, gleichartige Geschäfte von MF Infinity mit dem Kunden, auch wenn darauf nicht gesondert Bezug genommen wird. Maßgeblich ist jeweils die zum Zeitpunkt des Vertragsschlusses gültige Fassung.</p>
    <p>Bei Rechtsgeschäften zwischen MF Infinity und Verbrauchern iSd KSchG, gelten die gegenständlichen AGB soweit, als dadurch nicht gegen zwingendes Konsumentenschutzrecht verstoßen wird.</p>
    <p>Mündliche Vereinbarungen, insbesondere Zusagen von Mitarbeitern von MF Infinity, bedürfen für ihre Gültigkeit der schriftlichen Bestätigung. Von MF Infinity eingesetzte Subunternehmer oder Kooperationspartner sind nicht berechtigt, für MF Infinity verbindliche Vereinbarungen zu treffen.</p>
    <p>&nbsp;</p>
    </>
  ):(
    <>
    <p>Fatah Mohammad  provides supplies and services in the field of interior design and furnishing. Furthermore, Fatah Mohammad brokers contracts concerning facility management, construction projects, renovations and other craftsmen's services. </p>
    <p>The following General Terms and Conditions (in short "GTC") shall apply to all deliveries and services provided by "Fatah Mohammad" to the contractual partner (consumer or entrepreneur). In the following, Fatah Mohammad shall be referred to as "MF Infinity" and the respective contractual partner as "Customer".</p>
    <p>With the conclusion of the contract the client accepts these GTC in a legally binding way and in full, so that they have become part of the contract. This also applies in the event that a customer refers to its own terms and conditions. Any terms and conditions of the Client are expressly excluded unless they have been expressly accepted in writing by MF Infinity.</p>
    <p>These GTC also apply to future similar transactions of MF Infinity with the Customer, even if no separate reference is made thereto. The version valid at the time of the conclusion of the contract shall be authoritative in each case.</p>
    <p>In the case of legal transactions between MF Infinity and consumers within the meaning of the Austrian Consumer Protection Act (KSchG), the present General Terms and Conditions shall apply to the extent that they do not violate mandatory consumer protection law.</p>
    <p>Agreements made orally, in particular promises made by employees of MF Infinity, must be confirmed in writing in order to be valid. Subcontractors or cooperation partners engaged by MF Infinity are not entitled to enter into binding agreements on behalf of MF Infinity.</p>
    <p>&nbsp;</p>
    </>
  ) 
    }
    <ol start={2}>
      <li className={classes.headline}>{ props.lang === "de" ? "Angebote, Auftragsbestätigung, Kostenvoranschläge:" : "Offers, order confirmation, cost estimates:"}</li>
    </ol>
    {props.lang === "de" ?(
    <>
 <p>Die Angebote und Kostenvoranschläge von MF Infinity sind unverbindlich, sofern nicht ausdrücklich und schriftlich etwas anderes vereinbart wurde.</p>
    <p>Der Vertrag mit dem Kunden kommt mit dem Zugang einer schriftlichen Auftragsbestätigung von MF Infinity beim Kunden oder schlüssig durch Leistungserbringung und/oder Rechnungslegung durch MF Infinity zustande. Die Zugangsbestätigung einer Bestellung stellt noch keine verbindliche Annahme der Bestellung dar. Stillschweigen von MF Infinity gilt nicht als Einverständnis. MF Infinity behält sich gegenüber Verbrauchern eine Annahmefrist von einer Woche, gegenüber Unternehmern eine angemessene Annahmefrist vor. Weiters ist MF Infinity ist berechtigt, die Annahme einer Bestellung ohne Angabe von Gründen abzulehnen.</p>
    <p>Der Kunde ist verpflichtet, den Inhalt der Auftragsbestätigung zu überprüfen und Abweichungen zu dem von ihm übermittelten Auftrag unverzüglich schriftlich zu rügen. Andernfalls kommt das Rechtsgeschäft mit dem Inhalt der Auftragsbestätigung zustande.</p>
    <p>Nachträgliche Änderungen und Ergänzungen des Vertrages bzw. der Bestellung durch den Kunden bedürfen zu ihrer Gültigkeit der schriftlichen Bestätigung von MF Infinity. Bei Änderungen ist MF Infinity berechtigt, die Preise entsprechend anzupassen.</p>
    <p>Die in Katalogen, Prospekten, Preislisten udgl. enthaltenen Angaben sowie sonstige schriftliche oder mündliche Äußerungen sind nur maßgeblich, wenn in der Auftragsbestätigung ausdrücklich auf sie Bezug genommen wird. Beschreibungen des Liefergegenstandes und technische Angaben sind unverbindlich und gelten nicht als Zusicherung bestimmter Eigenschaften.</p>
    <p>Sofern nichts anderes vereinbart wird, sind die Kostenvoranschläge, Skizzen, Entwürfe und Muster von MF Infinity entgeltlich. Mangels anderer Vereinbarung ist MF Infinity berechtigt, ein Entgelt iHv 10 % der Nettoangebotssumme zu verrechnen. Kostenvorschläge, Angebote, Skizzen und Entwürfe werden nach bestem Fachwissen erstellt, eine Gewähr für deren Richtigkeit wird von MF Infinity jedoch nicht übernommen. Insbesondere können auf auftragsspezifische Umstände, die außerhalb der Erkennbarkeit von MF Infinity liegen, kein Bedacht genommen werden.</p>
    <p>Sollten sich nach Auftragserteilung unvermeidliche Kostenüberschreitungen von weniger als 10 % der Nettoauftragssumme ergeben, so können diese Kosten ohne eine gesonderte Verständigung des Kunden von MF Infinity in Rechnung gestellt werden. Handelt es sich um Kostenerhöhungen im Ausmaß von mehr als 10 %, ist der Kunde hierüber unverzüglich zu verständigen. Der Kunde hat dann die Möglichkeit, binnen 5 Tagen ab Verständigung vom Vertrag zurücktreten, wobei der Rücktritt schriftlich zu erfolgen hat. Die Kosten für den von MF Infinity bereits getätigten Aufwand sowie für die bisher erbrachten Lieferungen und Leistungen sind vom Kunden zu tragen. Sofern der Kunde binnen genannter Frist nicht den Rücktritt erklärt, gilt die Kostenüberschreitung als von ihm genehmigt.</p>
    <p>MF Infinity behält sich das Recht vor, bei Verträgen mit einer vereinbarten Liefer- bzw. Leistungszeit von mehr als zwei Monaten ab Vertragsabschluss allfällige Preiserhöhungen (zB hinsichtlich Material, Energie, kollektivvertragliche Lohnerhöhungen, Steuererhöhungen oä.) an den Kunden weiter zu verrechnen. Gleiches gilt für den Fall, dass sich die Leistungserbringung nach Vertragsabschluss aus Gründen verzögert, die nicht aus der Sphäre von MF Infinity stammen (zB aufgrund der Klärung der technischen oder rechtlichen Voraussetzungen). Auch Mehrkosten infolge von vom Kunden gewünschter Überstunden, Nacht- oder Sonntagsarbeit, können von MF Infinity zusätzlich verrechnet werden.</p>
    <p>MF Infinity ist berechtigt, die ihr obliegenden Aufgaben ganz oder teilweise durch Dritte (Subunternehmen, Kooperationspartner) erbringen zu lassen. Sofern es sich nicht um eine Vertragsvermittlung von MF Infinity an den Kunden handelt, entsteht kein wie immer geartetes direktes Vertragsverhältnis zwischen dem Dritten und dem Auftraggeber.</p>
    <p>&nbsp;</p>
    </>
  ):(
    <>
   <p>MF Infinity's quotations and cost estimates are non-binding unless expressly agreed otherwise in writing.</p>
    <p>The contract with the Customer shall come into existence upon receipt by the Customer of a written order confirmation from MF Infinity or conclusively through the provision of services and/or invoicing by MF Infinity. The confirmation of receipt of an order does not constitute a binding acceptance of the order. Silence on the part of MF Infinity shall not be deemed to constitute acceptance. MF Infinity reserves the right to a period of acceptance of one week vis-à-vis consumers and a reasonable period of acceptance vis-à-vis entrepreneurs. Furthermore, MF Infinity is entitled to refuse acceptance of an order without stating reasons.</p>
    <p>The Customer is obliged to check the content of the order confirmation and to immediately notify MF Infinity in writing of any deviations from the order submitted by it. Otherwise, the legal transaction shall be concluded with the content of the order confirmation.</p>
    <p>Subsequent amendments and additions to the contract or the order by the Customer require MF Infinity's written confirmation in order to be valid. In the event of changes MF Infinity shall be entitled to adjust the prices accordingly.</p> <p>The prices quoted in catalogues shall be subject to change without notice. </p> 
    <p>The information contained in catalogues, brochures, price lists and the like as well as other written or oral statements shall only be authoritative if expressly referred to in the order confirmation. Descriptions of the delivery item and technical specifications are non-binding and do not constitute a guarantee of specific properties.</p> <p>If nothing to the contrary is stated in the order confirmation, this shall not apply. </p>
    <p>Unless otherwise agreed, MF Infinity's cost estimates, sketches, drafts and samples shall be free of charge. Unless otherwise agreed, MF Infinity shall be entitled to charge a fee in the amount of 10 % of the net offer amount. Cost proposals, offers, sketches and drafts are prepared to the best of MF Infinity's knowledge, but MF Infinity does not assume any warranty for their correctness. In particular, no account can be taken of circumstances specific to the order which are beyond MF Infinity's control.</p>
    <p>If unavoidable cost overruns of less than 10 % of the net order amount occur after the order has been placed, such costs may be invoiced by MF Infinity without any separate notification to the Client. In the event of cost increases of more than 10 %, the Client shall be notified thereof without undue delay. The Customer shall then have the option to withdraw from the contract within 5 days of such notification, such withdrawal having to be made in writing. The costs for the expenses already incurred by MF Infinity as well as for the deliveries and services provided so far shall be borne by the Customer. If the Customer does not declare its withdrawal within the aforementioned period, the excess costs shall be deemed to have been accepted by the Customer. </p>
    <p>MF Infinity reserves the right to pass on to the customer any price increases (e.g. with regard to materials, energy, collective wage increases, tax increases, etc.) in the case of contracts with an agreed delivery or service period of more than two months from the conclusion of the contract. The same shall apply in the event that the performance of the services is delayed after the conclusion of the contract for reasons which do not originate from the sphere of MF Infinity (e.g. due to the clarification of technical or legal requirements). Additional costs resulting from overtime, night or Sunday work requested by the Client may also be additionally charged by MF Infinity.</p>
    <p>MF Infinity is entitled to have the tasks incumbent upon it performed in whole or in part by third parties (subcontractors, cooperation partners). Insofar as this is not a contract brokerage by MF Infinity to the Client, no direct contractual relationship whatsoever shall arise between the third party and the Client.</p>
    <p>&nbsp;</p>
    </>
  ) 
    }
    <ol start={3}>
      <li className={classes.headline}>{props.lang === 'de' ? 'Preise und Zahlungskonditionen:' : 'Prices and terms of payment:'}</li>
    </ol>
   
    {props.lang === "de" ?(
    <>
<p>Die vereinbarten Preise verstehen sich in Euro zuzüglich Umsatzsteuer in der jeweils gesetzlichen Höhe, sofern nicht ausdrücklich etwas anderes vereinbart wurde. Wurden in der Bestellung bzw. Auftragsbestätigung die Preise in einer Fremdwährung angegeben, behält sich MF Infinity das Wahlrecht vor, die Rechnung in Euro oder in der ursprünglichen Fremdwährung auszustellen.</p>
    <p>Sofern nicht ausdrücklich Gegenteiliges vereinbart wurde, beinhalten die Preise nicht die Kosten für Transport, Verpackung, Verladung, Montage bzw. Demontage, Rücknahme oder ordnungsgemäße Verwertung und Entsorgung. Derartige Leistungen erfolgen nur bei ausdrücklicher Vereinbarung und gegen Verrechnung eines gesonderten Entgelts.</p>
    <p>Ist die Lieferung der Ware vereinbart, so werden die Kosten der Lieferung gesondert verrechnet. Eine allenfalls vom Kunden gewünschte Transportversicherung sowie im Zusammenhang mit der Lieferung angefallene Gebühren, Steuern oder sonstige Abgaben trägt ebenfalls der Kunde.</p>
    <p>MF Infinity ist berechtigt, zur Deckung seines Aufwandes Akontozahlungen zu verlangen. Leistet der Kunde die verrechnete Anzahlung nicht fristgerecht, trifft MF Infinity keine Liefer- bzw. Leistungsverpflichtung. Werden Teilleistung erbracht, ist MF Infinity berechtigt, Teilrechnungen zu legen. Tritt vor der Lieferung eine Verschlechterung in den Vermögensverhältnissen des Kunden ein, ist MF Infinity berechtigt Vorauszahlung zu verlangen.</p>
    <p>Die Rechnungen von MF Infinity sind, sofern nichts anderes vereinbart, binnen 7 Tagen ab Rechnungsdatum ohne Abzüge zur Zahlung fällig. Bei Teilrechnungen sind die entsprechenden Teilzahlungen binnen 7 Tagen ab Erhalt der jeweiligen Teilrechnung zur Zahlung fällig. Der Kunde erklärt sich mit der Übersendung der Rechnung im elektronischen Wege einverstanden.</p>
    <p>Die Zahlung hat mittels Banküberweisung zu erfolgen. MF Infinity ist nicht verpflichtet sonstige Zahlungsmittel (zB Bar, Kreditkarte, Wechsel oder Schecks) anzunehmen. Eine ausnahmsweise Annahme erfolgt nur zahlungshalber. Sämtliche Diskont-, Einziehungsspesen oder sonstige mit unbaren Zahlungen verbundenen Kosten trägt der Kunde.</p>
    <p>Bei Zahlungseinstellung oder Zahlungsverzug des Kunden werden vereinbarte Rabatte, Nachlässe, Bonifikationen etc. nicht gewährt, sodass vom Kunden die unverminderten Preise zu zahlen sind.</p>
    <p>lm Falle des Zahlungsverzuges ist der Kunde verpflichtet, MF Infinity sämtliche Mahn-, Inkasso- und Rechtsanwaltskosten zu ersetzen. Darüber hinaus werden bei Unternehmergeschäften – unbeschadet darüberhinausgehender Schadenersatzforderungen – Verzugszinsen in der Höhe von 9,2&nbsp;% p.a. über dem jeweiligen Basiszinssatz berechnet. Bei Verträgen mit Verbrauchern ist MF Infinity berechtigt Verzugszinsen iHv 4 % p.a. zu verrechnen. Weiters ist MF Infinity bei Zahlungsverzug berechtigt,</p>
    <ul>
      <li>alle weiteren Lieferungs-/Leistungsverpflichtungen bis zur Zahlung oder Sicherstellung des Rechnungsbetrages zurückzuhalten;</li>
      <li>eine angemessene Verlängerung der Lieferfristen vorzunehmen;</li>
      <li>sämtliche offene Forderungen aus diesem oder einem anderen Geschäft mit dem Kunden fällig stellen;</li>
      <li>die unter Eigentumsvorbehalt gelieferte Ware ohne Rücktritt vom Vertrag in Verwahrung zu nehmen, bis die gesamte Forderung samt Nebenkosten vollständig abgedeckt ist;</li>
      <li>unter Gewährung einer angemessenen Nachfrist vom Vertrag zurückzutreten.</li>
    </ul>
    <p>Eine Aufrechnung durch den Kunden ist nur dann zulässig, wenn dessen Gegenforderung von MF Infinity ausdrücklich und schriftlich anerkannt oder gerichtlich festgestellt wurde. Der Kunde ist nicht berechtigt, seine Rechte und Pflichten aus den mit MF Infinity geschlossenen Verträgen an Dritte abzutreten und zu übertragen.</p>
    <p>Eine Zurückbehaltung von Zahlungen durch den Kunden ist nur dann zulässig, wenn allfällige Gewährleistungs-, Garantie- oder Erfüllungsansprüche von MF Infinity ausdrücklich und schriftlich anerkannt oder gerichtlich festgestellt wurden. Bei Verbrauchern gelten die einschlägigen zwingenden Bestimmungen des Konsumentenschutzrechts.</p>
    <p>&nbsp;</p>
    </>
  ):(
    <>
  <p>The agreed prices are in Euro plus VAT at the respective statutory rate, unless expressly agreed otherwise. If prices were stated in a foreign currency in the order or order confirmation, MF Infinity reserves the right to choose whether to issue the invoice in euros or in the original foreign currency.</p>
    <p>Unless expressly agreed otherwise, the prices do not include the costs of transport, packaging, loading, assembly or disassembly, return or proper recycling and disposal. Such services shall only be provided if expressly agreed and against payment of a separate charge.</p>
    <p>If delivery of the goods is agreed, the costs of delivery shall be charged separately. Any transport insurance requested by the customer as well as any fees, taxes or other charges incurred in connection with the delivery shall also be borne by the customer.</p> <p>MF Infinity shall be entitled to charge a separate fee. </p>
    <p>MF Infinity is entitled to demand payments on account to cover its expenses. If the Customer fails to pay the invoiced down payment in due time, MF Infinity shall not be obliged to deliver or perform. If partial performance is rendered, MF Infinity shall be entitled to issue partial invoices. If the financial situation of the Customer deteriorates prior to delivery, MF Infinity shall be entitled to demand advance payment. </p>
    <p>MF Infinity's invoices are, unless otherwise agreed, due for payment within 7 days from the date of invoice without any deductions. In the case of partial invoices, the corresponding partial payments shall be due for payment within 7 days of receipt of the respective partial invoice. The customer agrees to the sending of the invoice electronically. </p>
    <p>Payment shall be made by bank transfer. MF Infinity is not obliged to accept any other means of payment (e.g. cash, credit card, bill of exchange or cheque). Acceptance by way of exception shall be on account of payment only. All discount charges, collection charges or other costs associated with non-cash payments shall be borne by the Customer. </p>
    <p>In the event of cessation or default of payment by the customer, agreed discounts, rebates, bonuses, etc. shall not be granted, so that the customer must pay the undiminished prices.</p>
    <p>In the event of default in payment, the Customer shall be obliged to reimburse MF Infinity for all reminder, collection and legal costs. In addition, in case of business transactions - without prejudice to any further claims for damages - default interest in the amount of 9.2&nbsp;% p.a. above the respective base rate will be charged. Bei Verträgen mit Verbrauchern ist MF Infinity berechtigt Verzugszinsen iHv 4 % p.a. zu verrechnen. Weiters ist MF Infinity bei Zahlungsverzug berechtigt,</p>
    <ul>
      <li>withhold all further delivery/service obligations until the invoice amount has been paid or secured;</li>
      <li>make a reasonable extension of the delivery deadlines;</li>
      <li>make all outstanding claims from this or any other transaction with the customer due and payable;</li>
      <li>to take custody of the goods delivered under retention of title without withdrawing from the contract until the entire claim including ancillary costs has been fully covered;</li>
      <li> to withdraw from the contract by granting a reasonable period of grace.</li>
    </ul>
    <p>A set-off by the Customer is only permitted if the Customer's counterclaim has been expressly acknowledged by MF Infinity in writing or has been determined by a court. The Customer is not entitled to assign and transfer its rights and obligations under the contracts entered into with MF Infinity to third parties.</p>
    <p>A retention of payments by the Customer is only permitted if any warranty, guarantee or performance claims have been expressly acknowledged by MF Infinity in writing or have been determined by a court. In the case of consumers, the relevant mandatory provisions of consumer protection law shall apply.</p>
    <p>&nbsp;</p>
    </>
  ) 
    }


    <ol start={4}>
      <li className={classes.headline}> {props.lang === "de" ? (
        <>
        &nbsp;Gefahrenübergang und Erfüllungsort:
        </>
      ): (
        <>
        &nbsp;Transfer of risk and place of performance:
        </>
      )} </li>
    </ol>
 
    {props.lang === "de" ?(
    <>
<p>Bei Lieferungen ist der der Sitz von MF Infinity der Erfüllungsort; bei Versand der Ort der Übergabe der Ware an den Spediteur. Sofern nicht ausdrücklich etwas anderes vereinbart wird, gilt die Lieferung der Ware als EXW gem. INCOTERMS® 2010 vereinbart. Bei Dienstleistungen außerhalb des Sitzes von MF Infinity (zB Betriebsstätte oder Wohnung des Kunden) gilt der in der Auftragsbestätigung genannte Erfüllungsort.</p>
    <p>Die Gefahrtragung geht jeweils am Erfüllungsort auf den Kunden über. Dies gilt auch, wenn der Transport durch MF Infinity durchgeführt oder organisiert und geleitet wird. Und zwar auch dann, wenn der Kunde die Annahme der gelieferten Ware verweigert, die Ware aus Verschulden des Kunden nicht geliefert bzw. versendet werden kann oder die vereinbarungsgemäß selbst abzuholende Ware trotz Bereitstellung und Verständigung hierüber vom Kunden nicht abgeholt bzw. übernommen wird.</p>
    <p>Im Falle der Versendung erfolgt die Lieferung nach Wahl von MF Infinity per Paketdienst, Post, Bahn oder Frachtführer. MF Infinity ist berechtigt, die Lieferung in Teilen vorzunehmen. Im Falle der Versendung per Nachnahme ist MF Infinity berechtigt, sachdienliche Versicherungen auf Kosten des Kunden abzuschließen.</p>
    <p>&nbsp;</p>
    </>
  ):(
    <>
 <p>In the case of deliveries, the place of performance shall be the registered office of MF Infinity; in the case of shipment, the place of handover of the goods to the carrier. Unless expressly agreed otherwise, the delivery of the goods shall be deemed to be EXW pursuant to INCOTERMS® 2010. In the case of services outside MF Infinity's place of business (e.g. business premises or home of the Customer) the place of performance stated in the order confirmation shall apply.</p>
    <p>The risk shall pass to the Customer at the place of performance. This shall also apply if the transport is carried out or organised and managed by MF Infinity. This shall also apply if the Customer refuses to accept the delivered goods, if the goods cannot be delivered or shipped due to the Customer's fault or if the goods to be collected by the Customer as agreed are not collected or taken over by the Customer despite the provision and notification thereof.</p> <p>In the event of shipment by MF Infinity, the risk shall pass to the Customer at the place of performance. </p>
    <p>In the event of dispatch, delivery shall be made at MF Infinity's option by parcel service, post, rail or carrier. MF Infinity shall be entitled to make the delivery in parts. In case of dispatch by cash on delivery MF Infinity is entitled to take out relevant insurances at the expense of the Customer.</p>
    <p>&nbsp;</p>
    </>
  ) 
    }

    <ol start={5}>
      <li className={classes.headline}>
        {props.lang === "de" ? (
          <>
          &nbsp;Lieferfristen, Mitwirkungspflicht und Annahmeverzug:
          </>
        ): (
          <>
          &nbsp;Delivery deadlines, cooperation obligation and acceptance of default:
          </>
        )}
      </li>
    </ol>
    {props.lang === "de" ?(
    <>
<p>Liefer- bzw. Leistungsfristen und -termine werden nach Möglichkeit eingehalten, sind jedoch – mit Ausnahme von ausdrücklich von MF Infinity zugesagten Fixgeschäften – unverbindlich und beginnen frühestens, wenn alle organisatorischen, technischen bzw. finanziellen Voraussetzungen vom Kunden erfüllt sind sowie eine allfällige Anzahlung bei MF Infinity eingegangen ist. Bei Änderungen bzw. Ergänzungen des Auftrages durch den Kunden – aus welchem Grund auch immer – behält sich MF Infinity eine angemessene Verlängerung der Liefer- bzw. Leistungszeit vor.</p>
    <p>Beim Warenversand gilt die Lieferfrist als eingehalten, wenn die Ware innerhalb des vereinbarten Zeitraums zum Versand gebracht oder vom Spediteur abgeholt worden ist. Verzögert sich die Lieferung oder Leistung aus Gründen, die von MF Infinity nicht zu vertreten ist (zB Ereignisse höherer Gewalt bewaffnete Auseinandersetzungen, behördliche Eingriffe und Verbote, Transport- und Verzollungsverzug, Transportschäden, Energie- und Rohstoffmangel, Arbeitskonflikte, Ausfall eines wesentlichen, schwer ersetzbaren Zulieferanten oder andere unvorhersehbare, mit zumutbaren Mitteln nicht abwendbare Ereignisse) ruhen die Leistungsverpflichtungen für die Dauer und im Umfang des Hindernisses und es verlängern sich die Fristen entsprechend. Derartige Umstände berechtigen auch dann zur Verlängerung der Lieferfrist, wenn sie bei Zulieferanten oder von MF Infinity herangezogenen Subunternehmen bzw. Kooperationspartnern eintreten. Sofern in einem solchen Fall die Leistungserbringung von MF Infinity endgültig unmöglich wird, sind beide Vertragsparteien zum Rücktritt vom Vertrag berechtigt.</p>
    <p>Der Kunde hat auf eigene Kosten dafür zu sorgen, dass die Leistungserbringung vor Ort faktisch möglich und rechtlich zulässig ist (zB Vorhandensein von Stromanschlüssen, Vorliegen von Baugenehmigung oder Bauanzeigen). Der Kunde ist für erforderlichen Sicherheitsvorkehrungen vor Ort verantwortlich. Den Mitarbeitern bzw. sonstigen Erfüllungsgehilfen von MF Infinity ist der zur Leistungserbringung erforderliche Zutritt zu gewähren. Verzögert sich die Lieferung oder Leistung aus Gründen, die der Kunde zu vertreten hat, gilt die Frist als eingehalten und die Leistung als vertragskonform erbracht, wenn ihm MF Infinity die Liefer- bzw. Leistungsbereitschaft meldet.</p>
    <p>Im Falle der Überschreitung einer ausdrücklich als verbindlich vereinbarten Fristen oder Termine kann der Kunde vom Vertrag nur zurücktreten, nachdem er MF Infinity schriftlich eine angemessene Nachfrist von mindestens 4 Wochen gesetzt hat und diese fruchtlos verstrichen ist. Schadenersatzansprüche, Deckungskäufe oder sonstige Ansprüche des Kunden wegen Nichterfüllung oder Verzug von MF Infinity sind ausgeschlossen, ausgenommen bei Nachweis von Vorsatz oder grober Fahrlässigkeit.</p>
    <p>Der Kunde ist verpflichtet, die gelieferten Waren, auch Teillieferungen bzw -leistungen, entgegenzunehmen. Allfällige bei der Abnahme festgestellte Mängel berechtigen den Kunden nicht zur An- bzw. Übernahmeverweigerung, sondern sind im Rahmen der Gewährleistung geltend zu machen. Bei Annahmeverzug des Kunden ist MF Infinity berechtigt, die Ware auf Kosten des Kunden einzulagern. Mit der Einlagerung gilt die Lieferpflicht von MF Infinity als erfüllt. Weiters kann MF Infinity bei An- bzw. Übernahmeverzug nach Setzung einer angemessenen Nachfrist vom Vertrag zurückzutreten und die Lieferung gegebenenfalls anderweitig verwerten. Allfällige aus dem An- bzw. Übernahmeverzug entstandenen Schadenersatzansprüche bleiben hiervon unberührt.</p>
    <p>&nbsp;</p>
    </>
  ):(
    <>
 <p>Delivery and performance deadlines and dates shall be complied with as far as possible, but - with the exception of fixed-date transactions expressly promised by MF Infinity - are non-binding and shall commence at the earliest when all organisational, technical and financial requirements have been fulfilled by the Customer and any down payment has been received by MF Infinity. In the event of changes or additions to the order by the Client - for whatever reason - MF Infinity reserves the right to a reasonable extension of the delivery or performance period.</p>
    <p>In the case of dispatch of goods, the delivery period shall be deemed to have been complied with if the goods have been dispatched or collected by the carrier within the agreed period. If the delivery or performance is delayed for reasons for which MF Infinity is not responsible (e.g. events of force majeure, armed conflicts, interventions and prohibitions by public authorities, delay in transport and customs clearance, transport damage, shortage of energy and raw materials, labour disputes, failure of an essential supplier which is difficult to replace or other unforeseeable events which cannot be averted by reasonable means), the performance obligations shall be suspended for the duration and to the extent of the impediment and the time limits shall be extended accordingly. Such circumstances shall also entitle to an extension of the delivery period if they occur at suppliers or subcontractors or cooperation partners used by MF Infinity. To the extent that in such a case the performance of MF Infinity becomes finally impossible, both contracting parties shall be entitled to rescind the contract.</p>
    <p>The Customer shall ensure at its own expense that the performance of the Services on site is factually possible and legally permissible (e.g. existence of power connections, existence of building permits or building notices). The customer is responsible for the necessary safety precautions on site. The employees or other vicarious agents of MF Infinity shall be granted the access necessary for the performance of the service. If the delivery or performance is delayed for reasons for which the Customer is responsible, the time limit shall be deemed to have been complied with and the performance shall be deemed to have been performed in accordance with the contract if MF Infinity notifies the Customer that the delivery or performance is ready.</p>
    <p>In the event that a time limit or deadline expressly agreed as binding is exceeded, the Customer may only withdraw from the contract after it has granted MF Infinity a reasonable grace period of at least 4 weeks in writing and such grace period has expired to no avail. Claims for damages, covering purchases or other claims of the Customer due to non-performance or default by MF Infinity are excluded, except in case of evidence of intent or gross negligence.</p>
    <p>The Customer is obliged to accept the delivered goods, including partial deliveries or services. Any defects discovered during acceptance do not entitle the customer to refuse acceptance, but must be asserted within the framework of the warranty. In the event of a delay in acceptance by the Customer, MF Infinity shall be entitled to store the goods at the Customer's expense. MF Infinity's delivery obligation shall be deemed to have been fulfilled upon such storage. Furthermore, in the event of a delay in delivery or acceptance MF Infinity may, after having granted a reasonable grace period, rescind the contract and, if necessary, dispose of the delivery elsewhere. Any claims for damages arising from the delay in delivery or acceptance shall remain unaffected thereby.</p>
    <p>&nbsp;</p>
    </>
  ) 
    }
    <ol start={6}>
      <li className={classes.headline}>{props.lang === "de" ? (
        <>
        &nbsp;Musterlieferungen:
        </>
      ) : (
        <>
        &nbsp;Sample deliveries:
        </>
      )
      
      }</li>
    </ol>
   {props.lang === "de" ? (
        <>
        <p>Auf separate Sondervereinbarung können Produkte als Muster zur Verfügung gestellt werden. Werden Muster vom Kunden behalten, werden diese nach Ablauf von vier Wochen bzw. nach Ablauf der vereinbarten Frist in Rechnung gestellt. Musterlieferungen können nur zurückgenommen werden, wenn sie der Originallieferung entsprechen, keine mechanische Montage durchgeführt wurde, und die Ware keine Beschädigung aufweist.</p>
    <p>&nbsp;</p>
        </>
      ) : (
        <>
        <p>On separate special agreement, products can be provided as samples. If samples are kept by the customer, they will be invoiced after four weeks or after expiry of the agreed period. Sample deliveries can only be taken back if they correspond to the original delivery, no mechanical assembly has been carried out, and the goods do not show any damage.</p>
    <p>&nbsp;</p>
        </>
      )
        }
    <ol start={7}>
      <li className={classes.headline}>{props.lang === "de" ? (
        <>
        &nbsp;Eigentumsvorbehalt:
        </>

      )
      : (
        <>
        &nbsp;Reservation of ownership:
        </>
      )
    }</li>
    </ol>
   
    {props.lang === "de" ? (
        <>
 <p>MF Infinity behält sich das Eigentum an sämtlichen von ihm gelieferten Waren bis zur vollständigen Bezahlungen der Rechnungsbeträge zuzüglich Zinsen und Nebenkosten vor.</p>
    <p>Der Kunde ist verpflichtet, die Ware während aufrechtem Eigentumsvorbehalt sorgfältig zu verwahren und pfleglich zu behandeln. Der Kunde trägt das gesamte Risiko für die Vorbehaltsware, insbesondere die Gefahr des Untergangs, des Verlusts oder der Verschlechterung.</p>
    <p>Der Kunde ist verpflichtet, MF Infinity unverzüglich von allfälligen Beschädigungen von oder Zugriffen Dritter auf die unter Eigentumsvorbehalt stehende Ware zu informieren. Gleiches gilt bei einem Besitzwechsel der Ware oder einer Änderung der Anschrift des Kunden.</p>
    <p>Für den Fall der Weiterveräußerung der unter Eigentumsvorbehalt stehende Ware vor der vollständigen Bezahlung tritt der Kunde bereits jetzt, ohne dass es einer weiteren Abtretungserklärung oder Verständigung bedarf, die ihm aus der Weiterveräußerung der Ware gegenüber seinem Abnehmer entstehenden Ansprüche zur Tilgung aller Forderungen samt Nebenansprüchen bis zur Höhe der offenen Forderung von MF Infinity an diese ab. Diese Abtretung gilt sinngemäß auch für den Fall der Be- und Verarbeitung, Verbindung oder Vermengung der Ware.</p>
    <p>Der Kunde ist nicht zu einer Verpfändung oder Sicherungsübereignung der Vorbehaltsware berechtigt und darf über diese auch nicht in anderer Weise zugunsten Dritter verfügen. Der Kunde hat MF Infinity von der Eröffnung eines Insolvenzverfahrens über sein Vermögen oder der Pfändung der unter Eigentumsvorbehalt stehende Ware unverzüglich zu verständigen und sämtliche erforderlichen Schritte zur Wahrung der Interessen von MF Infinity zu setzen.</p>
    <p>Für den Fall, dass MF Infinity die Vorbehaltsware berechtigt herausfordert, erklärt der Kunde hiermit ausdrücklich seine Zustimmung, dass MF Infinity die Räumlichkeiten bzw. das Gelände, auf dem sich die unter Eigentumsvorbehalt stehende Ware befindet, betreten und die Vorbehaltsware selbst entnehmen bzw. demontieren darf.</p>
    <p>Der Kunde haftet für alle Schäden, die MF Infinity durch einen Verstoß gegen diese Verpflichtungen entstehen.</p>
    <p>&nbsp;</p>
        </>
      ) : (
        <>
        <p>MF Infinity retains title to all goods delivered by it until full payment of the invoice amounts plus interest and ancillary costs.</p>
    <p>The customer is obliged to store the goods carefully and to treat them with care during the period of retention of title. The customer shall bear the entire risk for the goods subject to retention of title, in particular the risk of destruction, loss or deterioration.</p> <p>The customer shall be obliged to keep the goods in safe custody for the duration of the retention of title.</p>
    <p>The Customer is obliged to inform MF Infinity immediately of any damage to or access by third parties to the goods subject to retention of title. The same applies in the event of a change of possession of the goods or a change of address of the Customer.</p> <p>In the event of a change of address of the Customer, the Customer shall inform MF Infinity immediately. </p>
    <p>In the event that the goods subject to retention of title are resold prior to full payment, the Customer hereby assigns to MF Infinity, without any further declaration of assignment or notification being required, the claims against its customer arising from the resale of the goods for the settlement of all claims including ancillary claims up to the amount of MF Infinity's outstanding claim. This assignment shall also apply mutatis mutandis in the event of processing, combining or mixing of the goods. </p>
    <p>The Customer is not entitled to pledge or transfer by way of security the Reserved Goods and may not dispose of them in any other way for the benefit of third parties. The Customer shall notify MF Infinity without undue delay of the opening of insolvency proceedings against its assets or of the attachment of the goods subject to retention of title and shall take all necessary steps to safeguard MF Infinity's interests.</p> <p>In the event that MF Infinity is unable to obtain the goods subject to retention of title, the Customer shall not be entitled to pledge the goods subject to retention of title or to assign them by way of security.</p>
    <p>In the event that MF Infinity justifiably claims the Reserved Goods, the Customer hereby expressly agrees that MF Infinity may enter the premises or site where the Reserved Goods are located and remove or dismantle the Reserved Goods itself.</p>
    <p>The Customer shall be liable for all damages suffered by MF Infinity as a result of any breach of these obligations.</p>
    <p>&nbsp;</p>
        </>
      )
    }


    <ol start={8}>
      <li className={classes.headline}>
        {props.lang === "de" ? (
          <>
          &nbsp;Gewährleistung:
          </>
        )
        : (
          <>
          &nbsp;Warranty:
          </>
        )
      }
      </li>
    </ol>
    
    {props.lang === "de" ? (
      <>
      <p>Der Kunde hat die erbrachten Lieferungen und Leistungen unverzüglich nach Übergabe zu untersuchen und erkennbare Mängel, Fehlmengen oder Falschlieferungen unverzüglich, spätestens binnen 3 Werktagen ab Übernahme der Lieferung bzw. Leistung, sowie versteckte Mängel binnen 3 Werktagen ab ihrer Entdeckung schriftlich zu rügen. Sichtbare Beschädigungen an der Verpackung sind noch im Zuge der Übergabe dem übergebenden Spediteur oder sonstigen Erfüllungsgehilfen von MF Infinity anzuzeigen und anschließend unverzüglich an MF Infinity zu melden. Der gerügte Mangel ist ausreichend zu beschreiben und zu belegen, sodass er von MF Infinity überprüft werden kann. Bei unberechtigten Mängelrügen, die umfangreiche Nachprüfungen verursachen, behält sich MF Infinity vor, die Kosten der Prüfung dem Kunden in Rechnung zu stellen.</p>
    <p>Die Gewährleistungsfrist beträgt 12 Monate, soweit nicht für einzelne Liefergegenstände besondere Gewährleistungsfristen vereinbart sind. Dies gilt auch für Liefer- und Leistungsgegenstände, die mit einem Gebäude oder Grund und Boden fest verbunden sind. Für verbesserte oder ausgetauschte Teile beginnt die Gewährleistungsfrist von neuem zu laufen, endet diesfalls jedoch 6 Monate nach Ablauf der ursprünglichen Gewährleistungsfrist. Der Lauf der Gewährleistungsfrist beginnt mit dem Zeitpunkt des Gefahrenüberganges bei Lieferungen (siehe Punkt 4) bzw. der Übernahme bei Dienstleistungen. Wird die Annahme der Ware bzw. Übernahme der Dienstleistung aus vom Kunden zu vertretenden Gründen verzögert, verkürzt sich die Gewährleistungsfrist entsprechend.</p>
    <p>Die Vermutung der Mangelhaftigkeit gemäß § 924 ABGB wird ausgeschlossen. Den Kunden trifft die volle Beweislast für das Vorliegen der Gewährleistungsvoraussetzungen, insbesondere auch für die Rechtzeitigkeit der Mängelrüge gemäß §§ 377 f UGB. § 933b ABGB findet keine Anwendung.</p>
    <p>Geringfügige, den Verwendungszweck nicht beeinträchtigende Abweichungen von Angaben in Katalogen, Prospekten, Werbeschriften, Mustern sowie jegliche Abweichung von schriftlichen oder mündlichen Äußerungen, die nicht in den Vertrag aufgenommen worden sind, stellen keine Mängel dar und gelten vorweg als vom Kunden genehmigt (insb. naturbedingte Abweichungen bei Maßen, Farben, Holz- und Furnierbild, Maserung, Struktur uä.).</p>
    <p>Bei begründeten Mängeln ist MF Infinity berechtigt, innerhalb angemessener Frist nach eigener Wahl den Mangel zu verbessern, die Ware auszutauschen oder eine angemessene Preisminderung vorzunehmen.</p>
    <p>Der Kunde hat mindestens drei Verbesserungsversuche von MF Infinity zuzulassen und den Mitarbeitern oder sonstigen Erfüllungsgehilfen von MF Infinity den für die Mängelbehebung erforderlichen Zutritt zu gewähren. Bei Mängelbehebung vor Ort, werden die Anfahrtskosten in Rechnung gestellt. Der Kunde hat auf eigene Kosten dafür zu sorgen, dass die Verbesserung vor Ort möglich ist (zB Stromanschluss).</p>
    <p>MF Infinity ist berechtigt, die Verbesserung oder den Austausch der Lieferung oder Leistung zu verweigern, wenn dies unmöglich oder mit einem unverhältnismäßig hohen Aufwand verbunden wäre. Dann stehen dem Kunden die gesetzlichen Wandlungs- oder Preisminderungsrechte zu.</p>
    <p>Die gelieferte Ware bietet nur jene Sicherheit, die auf Grund von Ö-Normen, Bedienungsanleitungen, Vorschriften des Lieferzweckes über die Behandlung des Liefergegenstandes (z.B. Gebrauchs- oder Pflegeanleitung) und erforderliche Wartung, insbesondere im Hinblick auf die vorgeschriebenen Überprüfungen, und sonstigen gegebenen Hinweisen erwartet werden kann.</p>
    <p>Von der Gewährleistung ausgeschlossen sind solche Mängel, die aus falscher Lagerung, Nichtbeachtung der Montage- Betriebs-, Wartungs- oder Pflegeanweisungen, Überbeanspruchung, nachlässiger oder unrichtiger Behandlung oder Verwendung ungeeigneter Materialien entstehen. Dies gilt ebenso bei Mängeln, die auf vom Kunden beigestelltes Material bzw. den spezifischen örtlichen Umständen zurückzuführen sind. MF Infinity haftet auch nicht für Beschädigungen, die auf Handlungen Dritter, nachlässigem Umgang mit der Ware (zB Oberflächenbeschädigungen, Druckstellen bzw. Kratzer durch schwere oder spitze Gegenstände), ungewöhnliche klimatische Bedingungen, falscher Pflege, Fugenbildung im natürlichen Ausmaß oder chemische Einflüsse zurückzuführen sind. Die Gewährleistung für Verschleißteile ist ausgeschlossen.</p>
    <p>Bei Verbrauchern greifen die einschlägigen zwingenden Bestimmungen des Konsumentenschutzrechts.</p>
    <p>&nbsp;</p>

    </>

    ) : (
      <>
      <p>The Customer shall inspect the deliveries made and services rendered without undue delay after handover and notify MF Infinity in writing of any visible defects, shortages or wrong deliveries without undue delay, at the latest within 3 working days after taking over the delivery or service, and of any hidden defects within 3 working days after their discovery. Visible damage to the packaging shall be notified to the forwarding agent or other vicarious agent of MF Infinity in the course of the handover and shall thereafter be reported to MF Infinity without undue delay. The defect complained of shall be sufficiently described and substantiated so that it can be verified by MF Infinity. In the event of unjustified notifications of defects which cause extensive subsequent inspections, MF Infinity reserves the right to charge the costs of the inspection to the Customer.</p>
    <p>The warranty period shall be 12 months, unless special warranty periods have been agreed for individual delivery items. This also applies to delivery items and items of service that are firmly connected to a building or land. The warranty period shall start anew for improved or replaced parts, but in this case shall end 6 months after the expiry of the original warranty period. The warranty period shall commence at the time of transfer of risk in the case of deliveries (see point 4) or acceptance in the case of services. If the acceptance of the goods or the taking over of the service is delayed for reasons for which the customer is responsible, the warranty period shall be shortened accordingly. </p>
    <p>The presumption of defectiveness according to § 924 ABGB is excluded. The customer shall bear the full burden of proof for the existence of the warranty requirements, in particular also for the timeliness of the notice of defects pursuant to §§ 377 f UGB. § Section 933b ABGB shall not apply.</p>
    <p>Minor deviations from information in catalogues, brochures, advertising literature, samples, as well as any deviation from written or oral statements not included in the contract, which do not affect the intended use, do not constitute defects and are deemed to have been approved by the customer in advance (in particular natural deviations in dimensions, colours, wood and veneer appearance, grain, structure, etc.). </p>
    <p>In the event of justified defects, MF Infinity shall be entitled, within a reasonable period of time and at its own discretion, to improve the defect, to replace the goods or to make a reasonable price reduction.</p>
    <p>The Customer shall allow at least three attempts by MF Infinity to remedy the defect and shall grant MF Infinity's employees or other vicarious agents the access necessary to remedy the defect. In the event that defects are remedied on site, the travel costs shall be charged. The Customer shall ensure at its own expense that the on-site improvement is possible (e.g. power connection).</p>
    <p>MF Infinity is entitled to refuse the improvement or replacement of the delivery or service if this would be impossible or would involve a disproportionately high effort. In this case, the customer is entitled to the statutory rights of redhibition or price reduction.</p> <p>The delivered goods or services shall be returned to MF Infinity. </p>
    <p>The delivered goods only offer that level of safety that can be expected on the basis of Austrian standards, operating instructions, regulations of the delivery purpose regarding the treatment of the delivery item (e.g. instructions for use or care) and required maintenance, in particular with regard to the prescribed inspections, and other instructions given.</p>
    <p>Excluded from the warranty are such defects that arise from incorrect storage, non-observance of the assembly, operating, maintenance or care instructions, overuse, negligent or incorrect handling or use of unsuitable materials. This also applies to defects which are due to material provided by the customer or to the specific local circumstances. MF Infinity shall also not be liable for damage caused by the actions of third parties, negligent handling of the goods (e.g. surface damage, pressure marks or scratches caused by heavy or pointed objects), unusual climatic conditions, incorrect care, the formation of joints to a natural extent or chemical influences. The warranty for wear parts is excluded.</p>
    <p>In the case of consumers, the relevant mandatory provisions of consumer protection law shall apply.</p>
    <p>&nbsp;</p>
      
      </>

    )}
    <ol start={9}>
      <li className={classes.headline}>
        {props.lang === "de" ? (
         <>
         &nbsp;Haftung und Schadenersatz:
         </>  
        ) : (
          <>
          &nbsp;Liability and compensation:
          </> )  }
      </li>
    </ol>

    {props.lang === "de" ? (
      <>
      <p>Soweit dies nicht gegen zwingendes Recht verstößt, haftet MF Infinity nur im Falle von Vorsatz oder grober Fahrlässigkeit für den Ersatz von Schäden. Insbesondere für leichte Fahrlässigkeit, mittelbare Schäden, entgangenen Gewinn, Zinsverluste, unterbliebene Einsparungen, Folge- und Vermögensschäden, Schäden aus Ansprüchen Dritter sowie für Schäden, die durch ungeeignete oder unsachgemäße Verwendung, natürliche Abnützung, fehlerhafte oder nachlässige Behandlung bzw. Lagerung entstanden sind, haftet MF Infinity nicht.</p>
    <p>Eine allfällige Haftung ist auf typischer Weise mit dem Vertrag verbundenen und vorhersehbaren Schäden begrenzt sowie der Höhe nach auf den Nettoauftragswert und max. auf den Haftungshöchstbetrag der von MF Infinity abgeschlossenen Betriebshaftpflichtversicherung, beschränkt.</p>
    <p>Eine Haftung für fehlerhafte Produkte sowie für daraus resultierende Folgeschäden besteht für MF Infinity und dessen Vorlieferanten nur im Rahmen der zwingenden Bestimmungen des Produkthaftungsgesetzes. Schutzwirkungen zugunsten Dritter aus diesem Vertrag sind ausgeschlossen.</p>
    <p>Die Beweislast für das Vorliegen sämtlicher schadenersatzrechtlicher Anspruchsvoraussetzungen trägt der Kunde.</p>
    <p>Allfällige Schadenersatzansprüche gegenüber MF Infinity sind bei sonstiger Verjährung binnen sechs Monaten ab Kenntnis von Schaden und Schädiger gerichtlich geltend zu machen. Soweit Pönalen vereinbart wurden, sind darüberhinausgehende Ansprüche des Kunden ausgeschlossen.</p>
    <p>Gegenüber Verbrauchern gelten die einschlägigen zwingenden Bestimmungen des Konsumentenschutzrechts.</p>
    <p>&nbsp;</p>
      </>
    ) : (
      <>
      <p>So far as this does not violate mandatory law, MF Infinity shall only be liable for the compensation of damages in case of intent or gross negligence. In particular, MF Infinity shall not be liable for slight negligence, indirect damages, loss of profit, loss of interest, failure to make savings, consequential and pecuniary damages, damages arising from third party claims or for damages caused by unsuitable or improper use, natural wear and tear, faulty or negligent handling or storage.</p>
    <p>Any liability shall be limited to damages typically associated with the contract and foreseeable and limited in amount to the net order value and max. to the maximum liability amount of MF Infinity's business liability insurance.</p>
    <p>MF Infinity and its suppliers shall only be liable for defective products and for consequential damages resulting therefrom within the scope of the mandatory provisions of the Product Liability Act. Protective effects in favour of third parties arising from this contract are excluded.</p>
    <p>The burden of proof for the existence of all conditions for claims under the law on damages shall be borne by the Customer.</p>
    <p>Any claims for damages against MF Infinity shall be asserted in court within six months of knowledge of the damage and the damaging party, otherwise they shall be time-barred. Insofar as penalties have been agreed, any further claims of the Customer shall be excluded.</p> <p>In relation to consumers.</p>
    <p>The relevant mandatory provisions of consumer protection law shall apply to consumers.</p>
    <p>&nbsp;</p>
      </>
    )}

   
    <ol start={10}>
      <li className={classes.headline}>
        {props.lang === "de" ? (
          <>
          &nbsp;Rücktritt vom Vertrag:  
          </>
        ) : (
          <>
          &nbsp;Termination of the contract:
          </>
        )}

      </li>
    </ol>
    {props.lang === "de" ? (
      <>
       <p>MF MF Infinity ist berechtigt, den Vertrag aus wichtigen Gründen mit sofortiger Wirkung aufzulösen. Ein wichtiger Grund liegt insb. vor, wenn</p>
    <ul>
      <li>die Lieferung oder Leistung aus Gründen, die der Kunde zu vertreten hat, unmöglich oder trotz Setzung einer Nachfrist von 14 Tagen durch MF MF Infinity weiter verzögert wird;</li>
      <li>sich der Kunde, trotz schriftlicher Abmahnung mit einer Nachfristsetzung von 14 Tagen, in Annahme- oder Zahlungsverzug befindet;</li>
      <li>der Kunde, trotz schriftlicher Abmahnung mit einer Nachfristsetzung von 14 Tagen, wiederholt gegen wesentliche Vertragsverpflichtungen verstößt;</li>
      <li>berechtigte Bedenken hinsichtlich der Bonität des Kunden bestehen und dieser auf die Aufforderung von MF MF Infinity weder eine Vorauszahlungen noch eine taugliche Sicherheit leistet;</li>
      <li>über das Vermögen des Kunden ein Insolvenzverfahren eröffnet wird oder ein Antrag auf Einleitung eines Insolvenzverfahrens mangels Vermögen abgewiesen wird.</li>
    </ul>
    <p>Der Kunde ist berechtigt, den Vertrag aus wichtigen Gründen mit sofortiger Wirkung aufzulösen, wobei ein wichtiger Grund insb. vorliegt, wenn</p>
    <ul>
      <li>MF Infinity trotz schriftlicher Abmahnung mit Nachfristsetzung von 14 Tagen wiederholt gegen wesentliche Vertragsverpflichtungen verstößt;</li>
      <li>über das Vermögen von MF Infinity ein Insolvenzverfahren eröffnet wird oder ein Antrag auf Einleitung eines Insolvenzverfahrens mangels Vermögen abgewiesen wird.</li>
    </ul>
    <p>Der Rücktritt kann auch hinsichtlich eines noch offenen Teiles der Lieferung oder Leistung erklärt werden. Bei Rücktritt bereits erbrachte Teilleistungen bzw. -lieferungen sowie von MF Infinity bereits erbrachte Vorbereitungshandlungen und Dispositionen werden dem Kunden von MF Infinity verrechnet. Nach eigener Wahl kann MF Infinity auch die Rückstellung bereits gelieferter Waren zu verlangen.</p>
    <p>Für den Fall, dass der Kunde unberechtigt vom Vertrag zurücktritt oder MF Infinity aus Gründen, die vom Kunden zu vertreten sind vom Vertrag zurücktritt, ist MF Infinity berechtigt, eine Vertragsstrafe in Höhe von 10 % des Nettoauftragswerts zu verrechnen. Die Geltendmachung eines darüberhinausgehenden Schadenersatzes bleibt hiervon unberührt.</p>
    <p>&nbsp;</p>
      </>
    ) : (
      <>
      <p>MF Infinity is entitled to terminate the contract with immediate effect for good cause. An important reason exists in particular if</p>
  <ul>

  <li>the delivery or performance is impossible for reasons for which the Customer is responsible or is further delayed by MF Infinity despite the setting of a grace period of 14 days;</li>  
  <li>despite a written warning with a grace period of 14 days, the customer is in default of acceptance or payment;</li>
  <li>the customer, despite a written warning with a grace period of 14 days, repeatedly breaches essential contractual obligations;</li>
  <li>justified concerns exist with regard to the Customer's creditworthiness and the Customer fails to make any advance payment or provide any suitable security when requested to do so by MF Infinity;</li>
  <li>insolvency proceedings are opened against the customer's assets or an application for the institution of insolvency proceedings is rejected for lack of assets.</li>
      
      </ul>

<p>The customer shall be entitled to terminate the contract with immediate effect for good cause, whereby good cause shall be deemed to exist in particular if</p>
<ul>
  <li>MF Infinity repeatedly breaches essential contractual obligations despite a written warning with a grace period of 14 days;</li>
  <li>insolvency proceedings are opened against MF Infinity's assets or an application for the institution of insolvency proceedings is rejected for lack of assets.</li>

</ul>
<p>The withdrawal may also be declared with regard to an outstanding part of the delivery or performance. In the event of withdrawal, already performed partial performances or deliveries as well as the preparations and disposals already performed by MF Infinity shall be offset against the customer. MF Infinity may also require the retention of goods already delivered.</p>
<p>In the event that the customer withdraws without justification or MF Infinity withdraws from the contract for reasons for which the customer is responsible, MF Infinity shall be entitled to charge a contractual penalty of 10% of the net order value. The assertion of any further claims for damages shall remain unaffected by this.</p>
<p>&nbsp;</p>
      </>
    )}

   
    <ol start={11}>
      <li className={classes.headline}>
        {props.lang === "de" ? (
          <>
          &nbsp;Gewerbliche Schutzrechte und Urheberrecht:
          </>
        ) : (
          <>
          &nbsp;Intellectual property rights and copyright:
          </>
        )}
      </li>
    </ol>

    {props.lang === "de" ? (
      <>
          <p>Wird eine Lieferung oder Leistung von MF Infinity auf Grund von Konstruktionsangaben, Zeichnungen, Modellen oder sonstigen Spezifikationen des Kunden angefertigt, hat der Kunde sicherzustellen, dass er über die erforderlichen Rechte hierfür verfügt. Der Kunde wird MF Infinity von diesbezüglichen Ansprüchen Dritter wegen der Verletzung von Immaterialgüterrechten schad- und klaglos halten.</p>
    <p>Sämtliche von MF Infinity zur Verfügung gestellte Unterlagen wie zB Kostenvoranschläge, Angebote, Pläne, Skizzen, Entwürfe und Muster sowie die zugrunde liegenden Daten und technische Unterlagen einschließlich Leistungsverzeichnissen oä. bleiben im Eigentum von MF Infinity und dürfen ohne dessen ausdrücklicher, schriftlicher Zustimmung nicht anderweitig verwendet oder verwertet werden. Wenn der Auftrag – aus welchen Gründen auch immer – nicht ausgeführt wird, sind auf Verlangen von MF Infinity sämtliche Daten und Unterlagen unverzüglich herauszugeben.</p>
    <p>&nbsp;</p>
    <ol start={12}>
      <li className={classes.headline}>&nbsp;Besonderheiten iZm Vertragsvermittlung:</li>
    </ol>
    <p>Soweit die Vermittlung von Verträgen durch MF Infinity Vertragsgegenstand ist, gelten primär die folgenden Bestimmungen:</p>
    <p>Übermittelt der Kunde eine Anfrage bezüglich den benötigten Leistungen (zB Handwerkerleistung) an MF Infinity, stellt diese Anfrage lediglich eine unverbindliche Aufforderung zur Einholung von Angeboten bei dritten Unternehmen dar. MF Infinity trifft keine Tätigkeitsverpflichtung. Der Kunde hat die für eine Angebotserstellung erforderlichen Daten und Unterlagen vollständig und inhaltlich richtig an MF Infinity zu übermitteln. Weiters hat der Kunde sicherzustellen, dass er über die erforderlichen Rechte zu den übermittelten Daten und Unterlagen verfügt. MF Infinity übernimmt keinerlei Haftung hinsichtlich der vom Kunden bereitgestellten Daten. Der Kunde hat MF Infinity diesbezüglich schad- und klaglos halten.</p>
    <p>MF Infinity bemüht sich die Anfrage an geeignete Unternehmen weiterzuleiten und diese zur Legung eines entsprechenden Angebots zu veranlassen. Weder schuldet MF Infinity einen bestimmten Erfolg (zB Vertragsabschluss, bestimmte Konditionen oä), noch verpflichtet sich MF Infinity im Rahmen eines solchen Vermittlungsauftrags selbst die vom Kunden gesuchte Lieferungen oder Leistung zu erbringen.</p>
    <p>Der Kunde entscheidet eigenständig, ob und welches Angebot er annimmt. Er ist nicht zum Vertragsabschluss mit einem Unternehmen verpflichtet. Kommt ein Vertrag zwischen dem Kunden und dem vermittelten Unternehmen zustande, ist MF Infinity an diesem Vertragsverhältnis weder als Vertragspartner noch als Vertreter, Erfüllungsgehilfe oder in sonst einer Form beteiligt. MF Infinity übernimmt keinerlei Gewähr oder Haftung – in welcher Form auch immer – im Bezug auf das Vertragsverhältnis zwischen dem Kunden und dem Unternehmen. Insbesondere ist MF Infinity nicht für die Vertragsabwicklung (zB mängelfreie Leistungserbringung oder vollständige Zahlung) oder allfälliger mit dem Vertrag zusammenhängender Streitigkeiten verantwortlich. Es wird darauf hingewiesen, dass die vom Kunden gesuchte Leistung auf Seiten des Unternehmens bestimmte Qualifikationen, Berechtigungen, Genehmigungen oä. erfordern kann. Der Kunde wird angehalten, das Vorliegen dieser Voraussetzungen eigenständig zu überprüfen, da eine Überprüfung durch MF Infinity nicht erfolgt.</p>
    <p>Mangels anderer Vereinbarung ist MF Infinity berechtigt, dem Kunden eine Vermittlungsprovision iHv 10 % der Nettovertragssumme zu verrechnen. Der Provisionsanspruch entsteht mit Abschluss des Vertrages zwischen dem Kunden und dem Unternehmen und wird durch Rechnungslegung fällig gestellt. Allfällige Leistungs- oder Zahlungsstörungen im Vertragsverhältnis zwischen Kunden und Unternehmen berechtigen nicht zur Minderung oder Zurückbehaltung der Provision.</p>
    <p>&nbsp;</p>

      </>
    ) : (
      <>
          <p>If MF Infinity manufactures a delivery or service based on the construction specifications, drawings, models or other specifications of the customer, the customer must ensure that he has the necessary rights for this. The customer shall indemnify MF Infinity from third-party claims for infringement of intellectual property rights.</p>
    <p>All documents provided by MF Infinity, such as cost estimates, offers, plans, sketches, designs and samples as well as the underlying data and technical documents including performance lists, etc. remain the property of MF Infinity and may not be used or exploited without its express written consent. If the order is not executed for any reason, MF Infinity shall immediately return all data and documents on request.</p>
    <p>&nbsp;</p>
    <ol start={12}>
      <li className={classes.headline}>&nbsp;Special provisions regarding contract mediation:</li>
    </ol>
    <p>If MF Infinity mediates contracts, the following provisions apply primarily:</p>
    <p>If the customer submits an inquiry regarding the required services (e.g. craft service) to MF Infinity, this inquiry only constitutes an non-binding request to obtain offers from third parties. MF Infinity does not assume any obligation to act. The customer must provide the data and documents required for an offer in full and in content. The customer must also ensure that he has the necessary rights to the data and documents provided. MF Infinity assumes no liability with regard to the data provided by the customer. The customer shall indemnify MF Infinity in this regard.</p>
    <p>MF Infinity will try to forward the inquiry to suitable companies and to encourage them to submit an offer. Neither does MF Infinity owe a certain success (e.g. contract conclusion, certain conditions, etc.) nor does it commit itself in the context of such a mediation order to provide the service sought by the customer.</p>
    <p>The customer decides independently whether and which offer he accepts. He is not obliged to conclude a contract with a company. If a contract is concluded between the customer and the company mediated by MF Infinity, MF Infinity is neither a party to this contractual relationship nor a representative, agent or in any other way involved. MF Infinity assumes no warranty or liability - in whatever form - with regard to the contractual relationship between the customer and the company. In particular, MF Infinity is not responsible for the contract performance (e.g. flawless performance or full payment) or any disputes related to the contract. It should be noted that the service sought by the customer may require certain qualifications, authorizations, permits, etc. on the part of the company. The customer is advised to independently check the existence of these prerequisites, as MF Infinity does not check this.</p>
    <p>Unless otherwise agreed, MF Infinity is entitled to charge the customer a mediation commission of 10% of the net contract amount. The claim for commission arises with the conclusion of the contract between the customer and the company and is due for payment by invoice. Any performance or payment defaults in the contractual relationship between the customer and the company do not entitle the customer to set off or withhold the commission.</p>
    <p>&nbsp;</p>

      </>
    )}
    <ol start={13}>
      <li className={classes.headline}>
        {props.lang === 'de' ? (
          <>
          &nbsp;Geltendes Recht und Gerichtsstand:
          </>

        ) : (
          <>
          &nbsp;Applicable law and jurisdiction:
          </>
          )}
      </li>
    </ol>
    
    <p>{props.lang === 'de' ? (
          <>
          <p>Der Vertrag unterliegt ausnahmslos österreichischem Recht unter Ausschluss der Verweisungsnormen und des UN-Kaufrechts.</p>
    <p>Zur Entscheidung aller aus oder im Zusammenhang mit dem Vertrag entstehenden Streitigkeiten – einschließlich solcher über sein Bestehen oder Nichtbestehen – ist das sachlich zuständige Gericht am Sitz von MF Infinity ausschließlich zuständig. MF Infinity behält sich vor, an jedem anderen Gerichtsstand, insbesondere am Sitz des Kunden, zu klagen.</p>
    <p>&nbsp;</p>
          </>
        ) : (
          <>
          <p>The contract is subject to Austrian law exclusively excluding the reference rules and the UN Sales Convention.</p>
    <p>For the decision of all disputes arising out of or in connection with the contract – including those concerning its existence or non-existence – the competent court at the seat of MF Infinity is exclusively competent. MF Infinity reserves the right to sue at any other place of jurisdiction, especially at the seat of the customer.</p>
    <p>&nbsp;</p>
          </>
          )}
    </p>

    <ol start={14}>
      <li>
        {props.lang === 'de' ? (
          <>
          &nbsp;Allgemeines:
          </>
        ) : (
          <>
          &nbsp;General:
          </>
          )}
      </li>
    </ol>
    <p>{props.lang === 'de' ? (
          <>
          <p>Als Vertragssprache gilt die Sprache Deutsch vereinbart.</p>
    <p>Falls einzelne Bestimmungen des Vertrages oder dieser Bestimmungen unwirksam sein sollten, wird dadurch die Wirksamkeit der übrigen Bestimmungen nicht berührt. An die Stelle der unwirksame Bestimmung tritt eine wirksame Bestimmung, die dem wirtschaftlichen Zweck der unwirksamen Bestimmung möglichst nahekommt.</p>
    <p>Schriftstücke (zB Fakturen, Ablehnung des Auftrages, etc.), die dem Kunden an die zuletzt bekannt gegebene Anschrift übersandt werden, gelten in jedem Fall diesem als zugegangen, es sei denn, der Kunde hat MF Infinity nachweislich eine Änderung seiner Anschrift schriftlich bekannt gegeben.</p>
    <p>&nbsp;</p>
    <ol start={15}>
      <li className={classes.headline}>&nbsp;Verbrauchergeschäfte im Fernabsatz:</li>
    </ol>
    <p>Sofern der Kunde Verbraucher im Sinne des KSchG ist, sind auf Verträge, die im Wege des Fernabsatzes geschlossen wurden, die entsprechenden Bestimmungen des FAGG anzuwenden.</p>
    <p><strong>Widerrufsrecht:</strong></p>
    <p>Der Kunde hat das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen. Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag, an dem der Kunde oder ein von ihm benannter Dritter, der nicht der Beförderer ist, die Waren in Besitz genommen haben bzw. hat.</p>
    <p>Um das Widerrufsrecht auszuüben, hat der Kunde MF Infinity mittels einer eindeutigen Erklärung (per Post oder E-Mail) an</p>
    <p>&nbsp;</p>
    <p><em>Fatah Mohammad<br /></em>Mühlbachstraße 2, 4063 Hörsching<br />bzw. mf@infinity.at</p>
    <p>&nbsp;</p>
    <p>über seinen Entschluss, diesen Vertrag zu widerrufen, zu informieren. Zu diesem Zweck kann der Kunde hierfür das auch beigefügte Muster-Widerrufsformular verwenden, was jedoch nicht vorgeschrieben ist.</p>
    <p>Zur Wahrung der Widerrufsfrist reicht es aus, dass der Kunde die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absendet.</p>
    <p><strong>Widerrufsfolgen:</strong></p>
    <p>Wenn der Kunde den Vertrag widerruft, hat MF Infinity ihm alle Zahlungen, die er vom Kunden erhalten hat, mit Ausnahme der Lieferkosten und der zusätzlichen Kosten, die sich daraus ergeben, dass der Kunde eine andere Art der Lieferung als die von MF Infinity angebotene, günstigste Standardlieferung gewählt hat, unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über den Widerruf dieses Vertrags durch den Kunden bei MF Infinity eingegangen ist. Für diese Rückzahlung verwendet MF Infinity dasselbe Zahlungsmittel, das der Kunde bei der ursprünglichen Transaktion eingesetzt hat, es sei denn, es wurde ausdrücklich etwas anderes vereinbart. In keinem Fall wird dem Kunden von MF Infinity wegen dieser Rückzahlung Entgelte berechnet.</p>
    <p>MF Infinity kann die Rückzahlung verweigern, bis sie die Waren wieder zurückerhalten hat oder bis der Kunde den Nachweis erbracht hat, dass er die Waren zurückgesandt hat.</p>
    <p>Der Kunde hat die Waren unverzüglich und in jedem Fall spätestens binnen vierzehn Tagen ab dem Tag, an dem er MF Infinity über den Widerruf des Vertrags unterrichtet, an diese zurückzusenden oder zu übergeben.</p>
    <p>Die Frist ist gewahrt, wenn der Kunde die Waren vor Ablauf der Frist von vierzehn Tagen absendet.</p>
    <p>Der Kunde trägt die unmittelbaren Kosten der Rücksendung der Waren. Der Kunde hat für einen etwaigen Wertverlust der Waren nur aufzukommen, wenn dieser Wertverlust auf einen zur Prüfung der Beschaffenheit, Eigenschaften und Funktionsweise der Waren nicht notwendigen Umgang mit diesen zurückzuführen ist.</p>
    <p>ENDE DER WIDERRUFSBELEHRUNG</p>
    <p><strong>Muster-Widerrufsformular:</strong></p>
    <p>Wenn der Kunde den Vertrag widerrufen will, hat er die Möglichkeit, das nachstehende Formular auszufüllen und an MF Infinity zurückzusenden:</p>
    <p><br /><em>An<br />Fatah Mohammad<br />Schärdingerstraße 6<br />4061 Pasching<br />Email: mf@infinity.at<br /><br />Hiermit widerrufe ich den von mir abgeschlossenen Vertrag über den Kauf der folgenden Waren/die Erbringung der folgenden Dienstleistung (Unzutreffendes bitte streichen):<br /><br />Ware/Dienstleistung: <br />Bestellt am: <br />Erhalten am:<br />Name des Verbrauchers: <br />Anschrift des Verbrauchers: <br /><br />Datum:<br /><br />Unterschrift des Verbrauchers:<br />(nur bei Mitteilung auf Papier)</em></p>

          </>
        ) : (
          <>
          <p>The language of the contract is German.</p>
    <p>If individual provisions of the contract or these provisions are invalid, this does not affect the validity of the remaining provisions. In place of the invalid provision comes a valid provision that comes as close as possible to the economic purpose of the invalid provision.</p>
    <p>Documents (eg invoices, rejection of the order, etc.) that are sent to the customer at the last known address, are in any case considered to have reached this, unless the customer has notified MF Infinity in writing of a change of address.</p>
    <p>&nbsp;</p>
    <ol start={15}>
      <li className={classes.headline}>&nbsp;Consumer transactions in distance selling:</li>
    </ol>
    <p>If the customer is a consumer within the meaning of the KSchG, the corresponding provisions of the FAGG shall apply to contracts that are concluded by means of distance selling.</p>
    <p><strong>Right of withdrawal:</strong></p>
    <p>The customer has the right to withdraw from this contract within fourteen days without giving reasons. The withdrawal period is fourteen days from the day on which the customer or a third party designated by him, who is not the carrier, has taken possession of the goods or has done so.</p>
    <p>In order to exercise the right of withdrawal, the customer must inform MF Infinity by means of a clear statement (by post or e-mail) to</p>
    <p>&nbsp;</p>
    <p><em>Fatah Mohammad <br /></em>Neubauzeile 105A<br />or </p>

    <p>to inform him of his decision to withdraw from this contract. For this purpose, the customer can use the enclosed model withdrawal form, which is not mandatory.</p>
    <p>In order to comply with the withdrawal period, it is sufficient for the customer to send the notification of the exercise of the right of withdrawal before the withdrawal period expires.</p>
    <p><strong>Consequences of withdrawal:</strong></p>
    <p>If the customer withdraws from this contract, MF Infinity must return all payments that he has received from the customer, including delivery costs (with the exception of the additional costs resulting from the fact that the customer has chosen a type of delivery other than the cheapest standard delivery offered by MF Infinity), immediately and at the latest within fourteen days from the day on which the notification of the customer's withdrawal from this contract has been received by MF Infinity. For this repayment, MF Infinity will use the same means of payment that the customer used for the original transaction, unless something else has been expressly agreed. In no case will MF Infinity charge the customer for this repayment.</p>
    <p>MF Infinity may refuse repayment until it has received the goods back or until the customer has provided proof that he has sent the goods back.</p>
    <p>The customer must return the goods immediately and in any case within fourteen days from the day on which he informed MF Infinity of the withdrawal from this contract. The deadline is met if the customer sends the goods before the withdrawal period has expired.</p>
    <p>The customer bears the direct costs of returning the goods. The customer is only liable for a possible loss in value of the goods if this loss in value is due to a handling of the goods that is not necessary for the examination of the nature, characteristics and functioning of the goods.</p>
    <p>END OF THE WITHDRAWAL INSTRUCTION</p>
    <p><strong>Model withdrawal form:</strong></p>
    <p>If the customer wants to withdraw from the contract, he has the option of filling out the following form and sending it back to MF Infinity:</p>
    <p><br /><em>To<br />Fatah Mohammad <br />Neubauzeile 105A<br />4020 Pasching<br />Email: mf@infinity.at oder db@infinity.at<br /><br /> I hereby revoke the contract concluded by me for the purchase of the following goods/provision of the following service (delete as appropriate):<br /><br />Goods/service: <br />Ordered on: <br />Received on:<br />Name of consumer: <br />Address of consumer: <br /><br />Date:<br /><br />Signature of consumer:<br />(only if notified on paper)</em></p>	 
          </>
        )}
        </p>




      </div>
  </div>
  <Footer lang={props.lang}></Footer>
  </>

  )
}

export default AGB
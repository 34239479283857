import './App.css';
import AGB from './components/AGB';
import Chalets from './components/Chalets';
import Datenschutz from './components/Datenschutz';
import Header from './components/Header';
import Impressum from './components/Impressum';
import Interior from './components/Interior';
import Nav from './components/Nav';
import Team from './components/Team';
import { useEffect,useState,} from 'react';
import Splashscreen from './components/Splashscreen';
import Sidenav from './components/Sidenav';
import { Switch,Route } from 'react-router-dom';
import Referenzen from './components/Referenzen';
import FacilityManagement from './components/FacilityManagement';
import ScrollToTop from './components/ScrollToTop';
import AOS from 'aos';
import 'aos/dist/aos.css';

const trans = [{
  "deNav": {
  bauandinterior: "Bau & Interior",
  hüttenandchalets: "Hütten & Chalets",
  facilitymanagement: "Facility Management",
  team: "Team",
  referenzen: "Referenzen",
  
},
  "enNav": {
  bauandinterior: "Construct. & Interior",
hüttenandchalets: "Huts & Chalets",
facilitymanagement: "Facility Management",
team: "Team",
referenzen: "References",
  }

  }]


let splash = true; 

function App() {
  const [splashScreen, setSplashScrenn] = useState(false);
  const [isopen, setisopen] = useState(false);
  const [lang, setlang] = useState('de');


  const langHandler = (e) => {
    console.log(e.target.value)
    setlang(e.target.value);
  };



  useEffect(() => {
    AOS.init();
    const SplashScreen = setTimeout(() => {
      setSplashScrenn(splash);
      splash = false;
    }, 3000);
  }, []);
  const toggle = () => {
    setisopen(!isopen);
  };

  return (
   <>    <ScrollToTop/>
    <Switch>
    {!splashScreen ? <Splashscreen></Splashscreen>
    : 
    //render this while splashscreen is shown



    <>


<Route path="/" exact>
      <Nav lang={lang} langHandler={langHandler} isopen={isopen} toggle={toggle} >
<Header lang={lang} />
<Sidenav lang= {lang} isopen={isopen} toggle={toggle} ></Sidenav>
</Nav>
    </Route>

    <Route path="/team" exact>
      <Nav lang={lang} langHandler={langHandler} isopen={isopen} toggle={toggle} >
<Team lang= {lang} />
<Sidenav lang={lang} langHandler={langHandler} isopen={isopen} toggle={toggle} ></Sidenav>
</Nav>
    </Route>
    

    <Route path="/facilitymanagement" exact>
      <Nav lang={lang} langHandler={langHandler} isopen={isopen} toggle={toggle} >


<FacilityManagement lang={lang}></FacilityManagement>
<Sidenav lang={lang} langHandler={langHandler} isopen={isopen} toggle={toggle} ></Sidenav>
</Nav>
    </Route>


    <Route path="/bau&interior" exact>
      <Nav lang={lang} langHandler={langHandler} isopen={isopen} toggle={toggle} >
<Interior lang={lang} />
<Sidenav lang={lang} langHandler={langHandler} isopen={isopen} toggle={toggle} ></Sidenav>
</Nav>
    </Route>
    
    
    
    <Route path="/hütten&chalets" exact >
      <Nav lang={lang} langHandler={langHandler} isopen={isopen} toggle={toggle} >
<Chalets lang={lang} />
<Sidenav lang={lang} langHandler={langHandler} isopen={isopen} toggle={toggle} ></Sidenav>
</Nav>
    </Route>


        
    <Route path="/referenzen" exact >
      <Nav lang={lang} langHandler={langHandler} isopen={isopen} toggle={toggle} >
<Referenzen lang={lang}></Referenzen>
<Sidenav lang={lang} langHandler={langHandler} isopen={isopen} toggle={toggle} ></Sidenav>
</Nav>
    </Route>

    <Route path="/agb" exact >
      <Nav lang={lang} langHandler={langHandler} isopen={isopen} toggle={toggle} >
<AGB lang= {lang} ></AGB>
<Sidenav lang={lang} langHandler={langHandler} isopen={isopen} toggle={toggle} ></Sidenav>
</Nav>
    </Route>

    <Route path="/datenschutz" exact >
      <Nav lang={lang} langHandler={langHandler} isopen={isopen} toggle={toggle} >
<Datenschutz lang= {lang} ></Datenschutz>
<Sidenav lang={lang} langHandler={langHandler} isopen={isopen} toggle={toggle} ></Sidenav>
</Nav>
    </Route>

    <Route path="/impressum" exact >
      <Nav lang={lang} langHandler={langHandler} isopen={isopen} toggle={toggle} >
<Impressum lang= {lang} ></Impressum>
<Sidenav lang={lang} langHandler={langHandler} isopen={isopen} toggle={toggle} ></Sidenav>
</Nav>
    </Route>
    
    
    </>}
    </Switch>
    </>

    
  );
}

export default App;

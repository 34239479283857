import React from 'react'
import "./Footer.css"
import { BsInstagram} from "react-icons/bs";
import { BsFacebook} from "react-icons/bs";
import logo from "../img/logo.webp"
import { NavLink } from 'react-router-dom';
import {AiFillLinkedin} from "react-icons/ai"
import {BsYoutube} from "react-icons/bs"


const Footer = (props) => {
  return (
    <section className="footer">
  <div className="main-footer">
    <div className="logoinfo" data-aos="fade-up">
      <a href='/'><img className="logo" alt='logo' src={logo}></img>
      </a>
      <div className="contact-details">
        <h1> {props.lang === "de" ?  "Kontaktieren Sie uns" : "Contact us"  }</h1>
        <li>
          <div className="fa fa-phone" />
          <a href="tel:+43676 9004029"> Mohammed F: +43 676 929 32 22</a>
        </li>
        <li>
          <div className="fa fa-envelope" />
          <a href="mailto:Infinity@office.at">office@infinity.at</a>
        </li>
      </div>
     
    </div>
    
    <div className="com" data-aos="fade-up">
    <h1>{props.lang === "de" ?  "Rechtliches" : "Law"  }</h1>
      <ul>
        <li>
          
          <NavLink to="/agb">{props.lang === "de" ? "AGB" : "GTC" }</NavLink>
        </li>
        <li>
         
          <NavLink to="/impressum">{props.lang === "de" ? "Impressum" : "Imprint" }</NavLink>
        </li>
        <li>
         
          <NavLink to="/datenschutz">{props.lang === "de" ? "Datenschutz" : "Privacy Policy" }</NavLink>
        </li>
      
      </ul>
    </div>
    <div className="info" data-aos="fade-up">
      <h1>Social Media</h1>
      <div className="sociallogos">
        <div className="logobox">
          <a rel='noreferrer' href="https://www.instagram.com/dminfinityog/?utm_medium=copy_link " target="_blank" className="fa fa-instagram" ><BsInstagram color='purple'/></a>
          <a rel='noreferrer' href="https://www.facebook.com/profile.php?id=100083209178440" target="_blank" className="fa fa-facebook" ><BsFacebook color='blue'/></a>
          <a rel='noreferrer' href="https://www.youtube.com/@dminfinity17" target="_blank" className="fa fa-facebook" > <BsYoutube color='red'/></a> 
        </div>

      </div>

    </div>
  </div>
  <footer className='copyright'>© Infinity Copyright 2023 All Rights Reserved</footer>
</section>

  )
}

export default  Footer
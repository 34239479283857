import React from 'react'
import classes from "./Partner.module.css"
import kunex from "../img/kunex_logo.webp"
import cb from "../img/CB_logo.webp"
import dana from "../img/Dana_logo.webp"
import hm from "../img/hartmann-logo.webp"
import murexin from "../img/murexin_logo.webp"
import nolte from "../img/Nolte_Logo.webp"
import sedda from "../img/Sedda_logo.webp"
import sht from "../img/sht-logo.webp"
import {MdHighQuality} from "react-icons/md"
import {AiFillCheckSquare,AiFillClockCircle} from "react-icons/ai"
import {BsFillFilePersonFill} from "react-icons/bs"
import {SiFastly} from "react-icons/si"
import {BsFillPatchCheckFill} from "react-icons/bs"
import aeg from "../img/Aeg.webp"
import ada from "../img/Ada.webp"
import schoess from "../img/Schoesswender.webp"
import tarkett from "../img/tarkett.webp"

const Partner = (props) => {
  return (
    <div className={classes.partner}>
        <div className={classes.container}>

          <div data-aos="fade-down" data-aos-duration="1500"   className={classes.text}>
            <h1>13</h1>
            <h2> {props.lang === "de" ?  "Jahre Erfahrung" : "Years of Experience"  }</h2>
            </div>

          

            <div data-aos="fade-down" data-aos-duration="1500"   className={classes.text2}>
            <h1>&gt; 3.TSD</h1>
            <h2> {props.lang === "de" ?  "Zufriedene Kunden" : "Statisfied customers"  }</h2>
          </div>

          {/* icon header and text */}
          <div className={classes.textContainer}>
            <div   className={classes.firstChild}>
              <MdHighQuality className={classes.icon} />
            <h4 className={classes.textHeader} > {props.lang === "de" ?  "Qualität" : "Quality"  } </h4>
            <h5 className={classes.textSub} > {props.lang === "de" ?  "Wird bei uns Groß geschrieben, nur das beste für unsere Kunden." : "Is capitalized with us, only the best for our customers."  } </h5>
          </div>
          <div  className={classes.firstChild}>
            <AiFillCheckSquare className={classes.icon} />
            <h4 className={classes.textHeader} > {props.lang === "de" ?  "Transparent" : "Transparency"  } </h4>
            <h5 className={classes.textSub} > {props.lang === "de" ?  "Kein kleingedrucktes oder versteckte Kosten!" : "No fine print or hidden costs!"  } </h5>
          </div>
          <div  className={classes.firstChild}>
            <BsFillFilePersonFill className={classes.icon} />
            <h4 className={classes.textHeader} > {props.lang === "de" ?  "Erfahrung" : "Experience"  } </h4>
            <h5 className={classes.textSub} > {props.lang === "de" ?  "Jahrelange Expertise die wir Ihnen anbieten können." : "Years of expertise that we can offer you."  } </h5>
          </div>
          </div>
          <div  className={classes.textContainer}>
          <div  className={classes.firstChild}>
            <AiFillClockCircle className={classes.icon} />
            <h4 className={classes.textHeader} > {props.lang === "de" ?  "Zeitgerecht" : "On time"  } </h4>
            <h5 className={classes.textSub} > { props.lang === "de" ?  "Sie werden nicht im Stich gelassen, wir halten was wir versprechen." : "You will not be let down, we deliver what we promise."  } </h5>
          </div>
          <div  className={classes.firstChild}>
            <SiFastly className={classes.icon} />
            <h4 className={classes.textHeader} > {props.lang === "de" ?  "Schnelle Abwicklung" : "Fast processing"  } </h4>
            <h5 className={classes.textSub} > { props.lang === "de" ?  "Unser Ziel ist es Ihre Anfragen schnellstmöglich zu beantworten." : "Our goal is to answer your inquiries as quickly as possible."  } </h5>
          </div>
          <div  className={classes.firstChild}>
            <BsFillPatchCheckFill className={classes.icon} />
            <h4 className={classes.textHeader} > {props.lang === "de" ?  "Garantie" : "Guarantee"  } </h4>
            <h5 className={classes.textSub} >{ props.lang === "de" ? "Wird bei uns Groß geschrieben, nur das beste für unsere Kunden." : "Is capitalized with us, only the best for our customers." } </h5>

          </div>
          </div>

</div>
    </div>
  )
}

export default Partner
import React from "react";
import CardsInt from "./CardsInt";
import classes from "./Interior.module.css";
import Nav from "./Nav";
import house from "../img/house.jpg";
import Footer from "./Footer";

const Interior = (props) => {
  return (
    <header className={classes.header}>
      <div className={classes.container}>
     
        <div className={classes.flex}>
          <section className={classes.containerText}>
            <h1> {props.lang === "de" ? "user job?" : "our job"}</h1>
            <p>
            
              {props.lang === "de" ? `unser Job ist es, besondere Menschen zu helfen, ihren ganz
              persönlichen Traum vom Wohnen und Arbeiten zu entwickeln - zu
              gestalten - und zu verwirklichen. Und damit wird es immer etwas
              Einzigartiges - auch für Dich - garantiert.`:`our job is to help special people to develop their very
              dream of living and working - to design it - and to realize it.
              design - and make it come true. And so it will always be something
              unique - also for you - guaranteed.` }
            </p>
          </section>
        </div>
        <section className={classes.cardcontainer}>
          <CardsInt lang = {props.lang}></CardsInt>
        </section>
      </div>
      <section><Footer lang= {props.lang}></Footer></section>
    </header>
  );
};

export default Interior;

import React from "react";
import classes from "./AboutUs.module.css";
import chalet from "../img/chalet.webp";
import interior from "../img/interior.webp";
import plan from "../img/plan.webp";
import einladung from "../img/einladung1.webp";
import einladung2 from "../img/einladung2.webp";
import Pdf2 from "../pdf/flyer.pdf";
const AboutUs = (props) => {
  return (
    <>
      <div className={classes.motivation}>
        <div className={classes.container}>
          <div data-aos="fade-right" data-aos-duration="1500"   className={classes["motivation_column-text"]}>
            <h1 className={classes["motivation_text-heading"]}>
              {props.lang === "de" ? "Über uns" : "About us"}{" "}
            </h1>
            <p className={classes["motivation-text"]}>
              {props.lang === "de"
                ? `Wir, die INFINITY sind ein Unternehmen mit Sitz in Linz, bestehend aus dem Geschäftsführer Mohammed, die es sich
            zum Ziel gemacht haben, ihr Alleinstellungsmerkmal auf den ALL IN
            ONE Service zu fokussieren. Die Marktetablierung erfolgt dabei mit
            den jahrzehntelangen Expertise des Geschäftsführer und wird über
            den vollständigen deutschsprachigen Raum ausgebaut werden. Zögern
            Sie nicht und lernen Sie uns in einem persönlichen Gespräch kennen.
            Hierbei können Sie sich von unseren Kompetenzen überzeugen.`
                : `We, the INFINITY  are a company with seat in Linz,
            consisting of the managing director Mohammed.
            to focus their unique selling proposition on ALL IN ONE service.
            ONE service. The market establishment takes place thereby with
            the decades of expertise of the managing directors and will be
            be expanded across the entire German-speaking region. Do not hesitate
            and get to know us in a personal conversation.
            Here you can convince yourself of our competences.`}
            </p>
          </div>
          <div data-aos="fade-left" data-aos-duration="1500"   className={classes["motivation_column-img"]}>
            <img
              className={`${classes["motivation_image"]} ${classes["motivation_image-first"]}`}
              src={chalet}
              alt="Traumbetreuer"
            ></img>
            <img
              className={`${classes["motivation_image"]} ${classes["motivation_image-second"]}`}
              src={interior}
              alt="Traumküche"
            ></img>
            <img
              className={`${classes["motivation_image"]} ${classes["motivation_image-third"]}`}
              src={plan}
              alt="Traumbad"
            ></img>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;

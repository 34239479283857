import React from 'react'
import logo from "../img/logo_new.webp"
import classes from "./Splashscreen.module.css"
import video from "../video/background_startsite.gif"

const Splashscreen = () => {
  return (
    <>
    <div className={classes.splash}>
    {/* <video className={classes["story__video"]}   loop
          muted
          autoPlay
          playsInline
         >
            <source type="video/mp4" src={video} />
    
    </video> */}

<img className={classes.gif} src={video} alt="loading..." />

    <img className={classes.image} src={logo}></img>
    </div>
    </>
  )
}

export default Splashscreen
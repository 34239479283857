import React from 'react'
import classes from "./Facility.module.css"
import Footer from './Footer';
import baustelle from "../img/facility/baustelle.webp"
import buero from "../img/facility/buero.webp"
import desinf from "../img/facility/desinf.webp"
import reini from "../img/facility/staubsauger.webp"
import schnee from "../img/facility/schnee.webp"
import umziehen from "../img/facility/umziehen.webp"
import { useState,useRef } from 'react';
import emailjs from 'emailjs-com';





const FacilityManagement = (props) => {
    const form = useRef();
    const [isFlipped, setIsFlipped] = useState(false);

  const closeFunc2 = (event) => {
    
    setIsFlipped(false);
  }
  const closeFunc = (event) => {
    event.preventDefault();
    setIsFlipped(!isFlipped);
  }

  const sendEmail = (e) => {
    e.preventDefault();
  
    emailjs.sendForm('service_cdksce8', 'template_w9ikxmk', form.current  , 'rWipc_p71bWM4AYNt')
      .then((result) => {
          console.log(result.text);
          console.log(form.current);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
      alert("Vielen Dank für Ihre Anfrage. Wir werden uns schnellstmöglich bei Ihnen melden.")
      setIsFlipped(!isFlipped);
    };
  
    return (
        <div className={classes.BG}>
          <section className={classes.motivation}>
            <div className={classes.container}>
              
              <h2 className={classes["motivation__text-heading"]}>Facility Management</h2>
              <p className={classes["motivation__text"]}>
              {props.lang === "de" ? `Wenn Sie sich Sauberkeit und größtmögliche Hygiene wünschen, dann sind Sie bei uns bestens aufgehoben. Mit unseren professionellen Leistungen stehen wir sowohl Privatkunden als auch Unternehmen und der Industrie zur Verfügung. Welche Leistungen in welchen Intervallen wir erbringen, vereinbaren wir gerne individuell mit Ihnen. Wir stehen für die Perfekte Sauberkeit und Maximale Hygiene.`:
              `If you want cleanliness and the greatest possible hygiene, then you are in good hands with us. With our professional services, we are available to private customers as well as companies and industry. We will be happy to agree with you individually which services we will provide and at what intervals. We stand for perfect cleanliness and maximum hygiene.`  }
              </p>
            </div>
          </section>
          <section className={classes.tours}>
            <div className={classes["tours__container"]}>
              <div className={classes["tours__cards"]}>
                <div className={classes["tours__card"]}>
                  <img className={classes["tours__card-img"]} src={reini} alt="Reinigung" />
                  <div className={classes["tours__card-icon-background"]} />
                  <div className={classes["tours__card-icon-container"]}>
                  </div>
                  <div className={classes["tours__card-content"]}>
                    <h5 className={classes["tours__card-heading"]}>{props.lang === "de" ? (
                      <>
                      Grund- &amp; <br />Sonderreinigung <br /> <br />
                      </>
                    ):(
                      <>
                      Basic- &amp; <br />Special cleaning <br /> <br />
                      </>
                    )}
                    </h5>
                    <p className={classes["tours__card-location"]}>
                    </p>
                    <p className={classes["tours__card-desc"]}>
                     {props.lang === "de" ? "Unsere Grund- und Sonderreinigungen sorgen für perfekte Hygiene und Sauberkeit.": "Our basic and special cleaning services ensure perfect hygiene and cleanliness."}

                    </p>
                  </div>
                </div>
                <div className={classes["tours__card"]}>
                  <img className={classes["tours__card-img"]} src={buero} alt="Reinigung" />
                  <div className={classes["tours__card-icon-background"]} />
                  <div className={classes["tours__card-icon-container"]}>
                  </div>
                  <div className={classes["tours__card-content"]}>
                    <h5 className={classes["tours__card-heading"]}>{props.lang === "de" ? (
                      <>
                      Unterhaltsreinigung<br /> <br /> <br />
                      </>
                    ):(
                      <>
                      Maintenance <br />  cleaning <br /> <br /> 
                      </>
                    )}</h5>
                    <p className={classes["tours__card-location"]}>
                      <span className={classes["tours__card-location-icon"]}>
                      </span>
                      <span className={classes["tours__card-location-text" ]}/>
                    </p>
                    <p className={classes["tours__card-desc"]}>
                      {props.lang === "de" ? "In regelmäßigen Abständen kümmern wir uns um die Reinigung von Gebäuden und Büros mit unserem speziellen Luftreinigungssystem.": "At regular intervals, we take care of cleaning buildings and offices with our special air purification system."}

                    </p>
                  </div>
                </div>
                <div className={classes["tours__card"]}>
                  <img className={classes["tours__card-img"]} src={baustelle} alt="Reinigung" />
                  <div className={classes["tours__card-icon-background"]} />
                  <div className={classes["tours__card-icon-container"]}>
                  </div>
                  <div className={classes["tours__card-content"]}>
                    <h5 className={classes["tours__card-heading"]}>{props.lang === "de" ? (
                      <>
                      Baureinigung<br /> <br /> <br />
                      </>
                    ):(
                      <>
                      Construction <br />  cleaning <br /> <br /> 
                      </>
                    )}</h5>
                    <p className={classes["tours__card-location"]}>
                      <span className={classes["tours__card-location-icon"]}>
                      </span>
                      <span className={classes["tours__card-location-text" ]}/>
                    </p>
                    <p className={classes["tours__card-desc"]}>
                     {props.lang === "de" ? " Wir sorgen auf Ihrer Baustelle vor der Übergabe für ordentliche, saubere Verhältnisse. ": "We ensure tidy, clean conditions on your construction site before handover."}
                    </p>
                  </div>
                </div>
                <div className={classes["tours__card"]}>
                  <img className={classes["tours__card-img"]} src={schnee} alt="Reinigung" />
                  <div className={classes["tours__card-icon-background"]} />
                  <div className={classes["tours__card-icon-container"]}>
                  </div>
                  <div className={classes["tours__card-content"]}>
                    <h5 className={classes["tours__card-heading"]}>
                      {props.lang === "de" ? (
                      <>
                      Hausbetreuung<br /> <br /> <br />
                      </>
                    ):(
                      <>
                      Housekeeping <br />  <br /> <br />
                      </>
                    )}
                    </h5>
                    <p className={classes["tours__card-location"]}>
                      <span className={classes["tours__card-location-icon"]}>
                      </span>
                      <span className={classes["tours__card-location-text" ]}/>
                    </p>
                    <p className={classes["tours__card-desc"]}>
                    {props.lang === "de" ? "Ob Schneeräumen, Gartenpflege oder Reinigungsarbeiten im Haus: auf uns können Sie sich verlassen.": "Whether it's snow clearing, garden maintenance or cleaning work in the house: you can rely on us."}
                    </p>
                  </div>
                </div>
                <div className={classes["tours__card"]}>
                  <img className={classes["tours__card-img"]} src={umziehen} alt="Reinigung" />
                  <div className={classes["tours__card-icon-background"]} />
                  <div className={classes["tours__card-icon-container"]}>
                  </div>
                  <div className={classes["tours__card-content"]}>
                    <h5 className={classes["tours__card-heading"]}>
                      {props.lang === "de" ? (
                      <>
                      Umzugsreinigung<br /> <br /> <br />

                      </>
                    ):(
                      <>
                      Moving <br />  cleaning <br /> <br />
                      </>
                    )}
                    </h5>
                    <p className={classes["tours__card-location"]}>
                      <span className={classes["tours__card-location-icon"]}>
                      </span>
                      <span className={classes["tours__card-location-text"]} />
                    </p>
                    <p className={classes["tours__card-desc"]}>
                     
                      {props.lang === "de" ? " Wenn Sie umziehen, übernehmen wir gerne die Umzugsreinigung und nehmen somit eine Last von Ihren Schultern. ": "If you are moving house, we are happy to take care of the removal cleaning, taking a load off your shoulders."}
                    </p>
                  </div>
                </div>
                <div className={classes["tours__card"]}>
                  <img className={classes["tours__card-img"]} src={desinf} alt="Reinigung" />
                  <div className={classes["tours__card-icon-background"]} />
                  <div className={classes["tours__card-icon-container"]}>
                  </div>
                  <div className={classes["tours__card-content"]}>
                    <h5 className={classes["tours__card-heading"]}>
                      {props.lang === "de" ? (
                      <>
                      Desinfektion<br /> <br /> <br />
                      </>
                    ):(
                      <>
                      Disinfection <br />  <br /> <br />
                      </>
                    )}
                    </h5>
                    <p className={classes["tours__card-location"]}>
                      <span className={classes["tours__card-location-icon"]}>
                      </span>
                      <span className={classes["tours__card-location-text"]} />
                    </p>
                    <p className={classes["tours__card-desc"]}>
                      
                      {props.lang === "de" ? " Perfekte Hygiene erhalten Sie durch eine Luft - und Oberflächendesinfektion – wir bieten auch Beratungen und Schulungen an.": "Perfect hygiene is achieved through air and surface disinfection - we also offer consultation and training."}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.buttonContainer}>
            <a
                  onClick={closeFunc2}
                    href="#booking-modal"
                    className="button button--slim button--secondary"
                  >
                    {props.lang === "de" ? "Jetzt Anfrage stellen!": "Make an enquiry now!"}
                  </a>

                  </div>
          </section>

          {!isFlipped &&  <section className="booking-modal" id="booking-modal">
          <div className="booking-modal__content">
          <a onClick={closeFunc} className="booking-modal__close" href="/bau&interior">
            x
          </a>
          <h3 className="booking-modal__heading">{props.lang === "de" ? "Jetzt Anfrage stellen!":"Make an enquiry now!"}</h3>
          <p className="booking-modal__paragraph">
          {props.lang === "de" ? "Für Anfragen jeglicher Art, sind bemüht so rasch wie möglich Ihnen zu Antworten.":"For enquiries of any kind, we will try to answer you as soon as possible. to answer you."}
          </p>
          <form ref={form} className="form" onSubmit={sendEmail} >
            <div className="form__horizontal">
              <div className="form__group">
                <input
                  type="text"
                  className="form__input"
                  id="booking-modal-name"
                  name="from_name"
                  placeholder="Name"
                />
                <label className="form__label" htmlFor="booking-modal-name">
                  {props.lang === "de" ? "Name":"Name"}
                </label>
              </div>
              <div className="form__group">
                <input
                  type="text"
                  className="form__input"
                  name="from_email"
                  id="booking-modal-email"
                  placeholder="E-Mail"
                /> 

                  <select name="category" className="form__input2">

                  <option  className="optionS" value="">{props.lang === "de" ? "Bitte wählen Sie eine Kategorie":"Please select a category"} </option>
    <option className="optionS" value="Bau">{props.lang === "de" ? "Handwerker":"Craftsman"}</option>
    <option className="optionS" value="Interior">Interior</option>
    <option className="optionS" value="Hütten">{props.lang === "de" ? "Chalets & Hütten":"Chalets & Cottages"}</option>
    <option className="optionS" value="Chalets">Facility Management</option>


                  </select>
                <label className="form__label" htmlFor="booking-modal-email">
                  E-Mail
                </label>
              </div>
            </div>
            <div className="form__radio-group">
              <div className="form_message">
                <textarea
                name="message"
                  className="form_message-message"
                  type="text"
                  placeholder="Message"
                ></textarea>
              </div>
            </div>
            <div className="booking-modal__submit-container">
              <input
                type="submit"
                className="button button--form"
                defaultValue="Anfrage abschicken!"
                value={props.lang === "de" ? "Anfrage abschicken":"Send enquiry"}
              />
            </div>
          </form>
        </div>
      </section>}
          <Footer lang={props.lang}></Footer>
        </div>
      );
}

export default FacilityManagement
import React from 'react'
import classes from './AGB.module.css'
import Footer from './Footer'

const Impressum = (props) => {
  return (
<>    
    <div className={classes.container}>
   <h1 className={classes.header}>
    {props.lang === "de" ? "Impressum" : "Imprint"}
   </h1>
    <div className={classes.containerText}>
   
  {props.lang === "de" ? (
    <>
     <p>
    Informationspflicht laut §5 E-Commerce Gesetz, §14 Unternehmensgesetzbuch,
    §63 Gewerbeordnung und Offenlegungspflicht laut §25 Mediengesetz.
  </p>
    </>
  ) : (
    <>
      <p>
    Information according to §5 E-Commerce Act, §14 Commercial Code, §63
    Trade Regulation and Disclosure according to §25 Media Act.
  </p>
    </>
  )}
  
  
  <p>
    Fatah Mohammad MF Infinity e.U
    <br />
    Neubauzeile 105A
    <br />
    4030 Linz
    <br />
    Austria
  </p>
  
  <br />
  
  <p>
   {props.lang === "de" ? (
    <>
     <strong className={classes.headline3}>Unternehmensgegenstand:</strong> Baumeister, Denkmal-, Fassden- und Gebäudereinigung, Handel mit Ware aller Art
    <br/>
    <strong className={classes.headline3}>ATU-Nummer:</strong>76369305<br />
    <strong className={classes.headline3}>Firmenbuchgericht:</strong> Linz
    <br/>
    <strong className={classes.headline3}>Firmensitz:</strong> Linz
    </>):(
      <>
      <strong className={classes.headline3}>Company:</strong> Master builder, cleaning of monuments, facades and buildings, trade in goods of all kinds
    <br/>
    <strong className={classes.headline3}>ATU-Number:</strong>76369305<br />
    <strong className={classes.headline3}>Company court:</strong> Linz
    <br/>
    <strong className={classes.headline3}>Company address:</strong> Linz
      </>
    )}
  </p>
 
  <br />
  <p>
   
    <strong className={classes.headline3}>E-Mail:</strong>{" "}
    <a href="mailto:office@infinity.at">office@infinity.at</a>
  </p>
  <br />
  <p>
   {props.lang === "de" ? (
    <>
     <strong className={classes.headline3}>Mitglied bei:</strong> WKO, Oberösterreich
    <br />
    <strong className={classes.headline3}>Berufsrecht:</strong> Gewerbeordnung: www.ris.bka.gv.at
    </> ):(
      <>
      <strong className={classes.headline3}>Member of:</strong> WKO, Upper Austria
    <br />
    <strong className={classes.headline3}>Professional code:</strong> Trade Regulation: www.ris.bka.gv.at
      </>
    )}
  </p>
  <br />
  <p>
    {props.lang === "de" ? (
      <>
      <strong className={classes.headline3}>Aufsichtsbehörde/Gewerbebehörde:</strong> Bezirkshauptmannschaft
    Linz
    <br />
    <strong className={classes.headline3}>Verleihungsstaat:</strong> Österreich
    </>):(
      <>
      <strong className={classes.headline3}>Supervisory authority:</strong> District office Hörsching
    <br />
    <strong className={classes.headline3}>Country of origin:</strong> Austria
      </>
    )}
  </p>
  <br />
  <p>
{props.lang === "de" ? (
      <>
          <strong className={classes.headline3}>Vertretungsbefugte Gesellschafter</strong>
    <br />
   Mohammad Fatah
    </>):(
      <>
          <strong className={classes.headline3}>Authorized representatives</strong>
    <br />
     Mohammad Fatah
      </>
    )}
  </p>
  <br />
  {props.lang === "de" ? (
      <>
      <h2 className={classes.headline}>EU-Streitschlichtung</h2>
      </>):(
        <>
        <h2 className={classes.headline}>EU dispute resolution</h2>
        </>
      )}

  <br />
 {props.lang === "de" ? (
      <>
       <p>
    Gemäß Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten
    (ODR-Verordnung) möchten wir Sie über die Online-Streitbeilegungsplattform
    (OS-Plattform) informieren.
    <br />
    Verbraucher haben die Möglichkeit, Beschwerden an die Online
    Streitbeilegungsplattform der Europäischen Kommission unter{" "}
    <a
      href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=DE"
      rel="follow noopener"
      target="_blank"
      className={classes.link}
    >
      https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=DE
    </a>{" "}
    zu richten. Die dafür notwendigen Kontaktdaten finden Sie oberhalb in
    unserem Impressum.
  </p>
  </>):(
    <>
     <p>
      According to Regulation on Online Dispute Resolution for Consumer
      Disputes (ODR Regulation), we would like to inform you about the
      Online Dispute Resolution Platform (OS Platform).
      <br/>
      Consumers have the possibility to submit complaints to the Online
      Dispute Resolution Platform of the European Commission via{" "}
      <a
        href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=DE"
        rel="follow noopener"
        target="_blank"
        className={classes.link}
      >
        https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=DE
      </a>{" "}
       The necessary contact details can be found above in our
      imprint.
    </p>
    </>
  )}
  <br />
{props.lang === "de" ? (
      <>
        <p>
    Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder
    verpflichtet sind, an Streitbeilegungsverfahren vor einer
    Verbraucherschlichtungsstelle teilzunehmen.
  </p>
  </>):(
    <>
      <p>
        However, we would like to point out that we are not obliged to participate
        in dispute resolution proceedings before a consumer arbitration board.
        </p>
        </>
        )}
  <br />
  {props.lang === "de" ? (
      <>
      <h2 className={classes.headline}>Haftung für Inhalte dieser Website</h2>
      </>):(
        <>
        <h2 className={classes.headline}>Liability for contents of this website</h2>
        </>
      )}
  <br />
{props.lang === "de" ? (
      <>
        <p>
    Wir entwickeln die Inhalte dieser Website ständig weiter und bemühen uns
    korrekte und aktuelle Informationen bereitzustellen. Leider können wir keine
    Haftung für die Korrektheit aller Inhalte auf dieser Website übernehmen,
    speziell für jene, die seitens Dritter bereitgestellt wurden. Als
    Diensteanbieter sind wir nicht verpflichtet, die von Ihnen übermittelten
    oder gespeicherten Informationen zu überwachen oder nach Umständen zu
    forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
  </p>
  </>):(
    <>
      <p>
        We constantly develop the contents of this website and strive to provide
        correct and current information. Unfortunately, we cannot assume any
        liability for the correctness of all contents on this website, especially
        for those provided by third parties. As a service provider, we are not
        obligated to monitor the information transmitted or stored by you or to
        investigate circumstances that indicate illegal activity.
        </p>
        </>
        )}
  <br />
{props.lang === "de" ? (
      <>
      <p>
    Unsere Verpflichtungen zur Entfernung von Informationen oder zur Sperrung
    der Nutzung von Informationen nach den allgemeinen Gesetzen aufgrund von
    gerichtlichen oder behördlichen Anordnungen bleiben auch im Falle unserer
    Nichtverantwortlichkeit davon unberührt.
  </p>
  </>):(
    <>
      <p>
        Our obligations to remove information or to block the use of information
        under general laws remain unaffected by this as far as our
        responsibility for this is concerned.
        </p>
        </>
        )}
  <br />
{props.lang === "de" ? (
      <>
        <p>
    Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen, bitte wir
    Sie uns umgehend zu kontaktieren, damit wir die rechtswidrigen Inhalte
    entfernen können. Sie finden die Kontaktdaten im Impressum.
  </p>
  </>):(
    <>
      <p>
        If you notice any problematic or illegal content, please contact us
        immediately so that we can remove the illegal content. You can find the
        contact details in the imprint.
        </p>
        </>
        )}
  <br />
  {props.lang === "de" ? (
      <>
      <h2 className={classes.headline}>Haftung für Links auf dieser Website</h2>
      </>):(
        <>
        <h2 className={classes.headline}>Liability for links on this website</h2>
        </>
      )}
  <br />
{props.lang === "de" ? (
      <>
        <p>
    Unsere Website enthält Links zu anderen Websites für deren Inhalt wir nicht
    verantwortlich sind. Haftung für verlinkte Websites besteht für uns nicht,
    da wir keine Kenntnis rechtswidriger Tätigkeiten hatten und haben, uns
    solche Rechtswidrigkeiten auch bisher nicht aufgefallen sind und wir Links
    sofort entfernen würden, wenn uns Rechtswidrigkeiten bekannt werden.
  </p>
  <br />
  <p>
    Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitte wir Sie
    uns zu kontakti
    eren. Sie finden die Kontaktdaten im Impressum.
  </p>
  </>):(
    <>
      <p>
        Our website contains links to other websites for whose content we are not
        responsible. Liability for linked websites exists for us only in the
        case that we had knowledge of illegal activities and could have prevented
        the visitors of our site from viewing the content in question. We will
        remove such links immediately if we become aware of any legal
        infringements.
        </p>
        <br/>
        <p>
          If you notice any illegal links on our website, please contact us. You
          can find the contact details in the imprint.
          </p>
          </>
          )}
  <br />
  {props.lang === "de" ? (
      <>
      <h2 className={classes.headline}>Urheberrechtshinweis</h2>
  <br />
  <p>
    Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos) unterliegen dem
    Urheberrecht. Bitte fragen Sie uns bevor Sie die Inhalte dieser Website
    verbreiten, vervielfältigen oder verwerten wie zum Beispiel auf anderen
    Websites erneut veröffentlichen. Falls notwendig, werden wir die unerlaubte
    Nutzung von Teilen der Inhalte unserer Seite rechtlich verfolgen.
  </p>
  <br />
  <p>
    Sollten Sie auf dieser Webseite Inhalte finden, die das Urheberrecht
    verletzen, bitten wir Sie uns zu kontaktieren.
  </p>
  <br />
  
  </>):(
    <>
      <h2 className={classes.headline}>Copyright notice</h2>
  <br />
  <p>
  All contents of this website (images, photos, texts, videos) are subject to copyright.
    copyright. Please ask us before you distribute, copy or use the contents of this website.
    distributed, reproduced or exploited, for example by republishing it on other websites.
    republish on other websites. If necessary, we will take legal action against unauthorised
    use of any part of the content of our site.
    </p>
    <br/>
    <p>
      If you find content on this website that violates copyright, please contact us.
      </p>
      <br/>
      </>
      )}
    
 {props.lang === "de" ? (
      <>
       <h2 className={classes.headline}>Bildernachweis</h2>
  <br />
  <p>
    Die Bilder, Fotos und Grafiken auf dieser Webseite sind urheberrechtlich
    geschützt.
  </p>
  <br />
  <p>Alle Texte sind urheberrechtlich geschützt.</p>
  <br />
  <p>
    Quelle: Erstellt mit dem{" "}
    <a
      href="https://www.adsimple.at/impressum-generator/"
      title="Impressum Generator von AdSimple für Österreich"
    >
      Impressum Generator
    </a>{" "}
    von AdSimple
  </p>
  </>):(
    <>
      <h2 className={classes.headline}>Image credits</h2>
      <br/>
      <p>
        The images, photos and graphics on this website are protected by copyright.
        </p>
        <br/>
        <p>All texts are protected by copyright.</p>
        <br/>
        <p>
          Source: Created with the{" "}
          <a
            href="https://www.adsimple.at/impressum-generator/"
            title="Impressum Generator von AdSimple für Österreich"
          >
            Impressum Generator
          </a>{" "}
          from AdSimple
          </p>
          </>
          )}
  <br />
  
</div>
</div>
<Footer></Footer>
</>
  )
}

export default Impressum;
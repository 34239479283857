import React from 'react'
import img1 from "../img/referenz/1.webp"
import img2 from "../img/referenz/1_1.webp"
import img3 from "../img/referenz/1_111.webp"
import img4 from "../img/referenz/1_11.webp"
import img5 from "../img/referenz/2.webp"
import img6 from "../img/referenz/2_2.webp"
import img7 from "../img/referenz/4_4.webp"
import img8 from "../img/referenz/7.webp"
import img9 from "../img/referenz/3.webp"
import img10 from "../img/referenz/5.webp"
import img100 from "../img/referenz/5_5.webp"
import img11 from "../img/referenz/pdf.png"
import img12 from "../img/referenz/6.webp"
import img13 from "../img/referenz/6_6.webp"
import img14 from "../img/referenz/9_999.webp"
import img15 from "../img/referenz/8.webp"
import img16 from "../img/referenz/8_88.webp"
import img160 from "../img/referenz/8_8.webp"
import classes from "./Referenz.module.css"
import img17 from "../img/referenz/4.webp"
import img18 from "../img/referenz/9_9.webp"
import img19 from "../img/referenz/9_99.webp"
import img20 from "../img/referenz/9.webp"
import Pdf from "../pdf/PaketInfinity.pdf"
import Footer from './Footer'
import im1 from "../img/referenz/referenz2/1.webp"
import im2 from "../img/referenz/referenz2/2.webp"
import im3 from "../img/referenz/referenz2/3.webp"
import im4 from "../img/referenz/referenz2/4.webp"
import im5 from "../img/referenz/referenz2/5.webp"
import im6 from "../img/referenz/referenz2/6.webp"
import im7 from "../img/referenz/referenz2/7.webp"
import im8 from "../img/referenz/referenz2/8.webp"
import im9 from "../img/referenz/referenz2/9.webp"
import im10 from "../img/referenz/referenz2/10.webp"
import im11 from "../img/referenz/referenz2/11.webp"
import Pdf2 from "../pdf/PaketInfinityWG1.pdf"

const Referenzen = (props) => {
    return (
<>
        <section className={classes.gallery}>
          <div className={classes["gallery__container"]}>
            <h3 className={classes["gallery__heading"]}>{ props.lang ==="de" ? "Projekt Thalheim EFH": "Project Thalheim SFH"}</h3>
            <h3 className={classes["gallery__heading2"]}>{ props.lang ==="de" ? "Schlüsselfertigpaket": "Turnkey package"}</h3>
            <div className={classes["gallery__list"]}>
              <a className={`${classes["gallery__list-item"]} ${classes["gallery__list-item--first"]}`} >
                <img className={classes["gallery__list-item-image"]} src={img1} alt="" />
              </a>
              <a className={classes["gallery__list-item"]} >
                <img className={classes["gallery__list-item-image"]} src={img2} alt="" />
              </a>
              <a className={classes["gallery__list-item"]} >
                <img className={classes["gallery__list-item-image"]} src={img3} alt="" />
              </a>
              <a className={classes["gallery__list-item"]} >
                <img className={classes["gallery__list-item-image"]} src={img4} alt="" />
              </a>
              <a className={classes["gallery__list-item"]} >
                <img className={classes["gallery__list-item-image"]} src={img5} alt="" />
              </a>
              <a className={classes["gallery__list-item"]} >
                <img className={classes["gallery__list-item-image"]} src={img6} alt="" />
              </a>
              <a className={classes["gallery__list-item"]} >
                <img className={classes["gallery__list-item-image"]} src={img7} alt="" />
              </a>
              <a className={classes["gallery__list-item"]} >
                <img className={classes["gallery__list-item-image"]} src={img17} alt="" />
              </a>
              <a className={classes["gallery__list-item"]} >
                <img className={classes["gallery__list-item-image"]} src={img18} alt="" />
              </a>
              <a className={classes["gallery__list-item"]} >
                <img className={classes["gallery__list-item-image"]} src={img19}  alt="" />
              </a>
              <a className={classes["gallery__list-item"]} >
                <img className={classes["gallery__list-item-image"]} src={img20} alt="" />
              </a>
              <a className={classes["gallery__list-item"]} >
                <img className={classes["gallery__list-item-image"]} src={img14} alt="" />
              </a>
            </div>
            <input className={classes["gallery__cb-toggle"]} id="gallery__cb-toggle" type="checkbox" autoComplete="off" />
            <div className={classes["gallery__more-button-container"]}>
              <label className={classes.button} htmlFor="gallery__cb-toggle"> { props.lang ==="de" ? "Mehr": "More"}</label>
            </div>
            <div className={`${classes["gallery__list"]} ${classes["gallery__list--cb-toggle"]}`}>
              <a className={classes["gallery__list-item"]} >
                <span className={classes["gallery__list-item-bg-image"]} style={{backgroundImage: `url(${img8})`}} />
              </a>
              <a className={classes["gallery__list-item"]} >
                <span className={classes["gallery__list-item-bg-image"]} style={{backgroundImage: `url(${img9})`}} />
              </a>
              <a className={classes["gallery__list-item"]} >
                <span className={classes["gallery__list-item-bg-image"]} style={{backgroundImage: `url(${img10})`}} />
              </a>
              <a className={classes["gallery__list-item"]} >
                <span className={classes["gallery__list-item-bg-image"]} style={{backgroundImage: `url(${img100})`}} />
              </a>
              <a className={classes["gallery__list-item"]} >
                <img className={classes["gallery__list-item-image"]} src={img15} alt="" />
              </a>
              <a className={classes["gallery__list-item"]} >
                <img className={classes["gallery__list-item-image"]} src={img160} alt="" />
              </a>
              <a className={classes["gallery__list-item"]} >
                <img className={classes["gallery__list-item-image"]} src={img16} alt="" />
              </a>
              <a className={classes["gallery__list-item"]} >
                <img className={classes["gallery__list-item-image"]} src={img12} alt="" />
              </a>
              <a className={classes["gallery__list-item"]} >
                <img className={classes["gallery__list-item-image"]} src={img13} alt="" />
              </a>
              <a className={classes["gallery__list-item"]} href = {Pdf} target = "_blank">
                <img className={classes["gallery__list-item-image"]} src={img11} alt="" />
              </a>
            </div>
          </div>
   





           
      
           <div className={classes["gallery__container"]}>
            <h3 className={classes["gallery__heading3"]}>{ props.lang === "de" ? "Projekt Thalheim WG": "Project Thalheim SF"}</h3>
            <h3 className={classes["gallery__heading2"]}>
              {props.lang === "de" ? "Wohnung Schlüsselfertigpaket": "Flat turnkey package"}
            </h3>
            <div className={classes["gallery__list2"]}>
              <a className={`${classes["gallery__list-item"]} ${classes["gallery__list-item--first"]}`} >
                <img className={classes["gallery__list-item-image"]} src={im1} alt="" />
              </a>
              <a className={classes["gallery__list-item"]} >
                <img className={classes["gallery__list-item-image"]} src={im2} alt="" />
              </a>
              <a className={classes["gallery__list-item"]} >
                <img className={classes["gallery__list-item-image"]} src={im3} alt="" />
              </a>
              <a className={classes["gallery__list-item"]} >
                <img className={classes["gallery__list-item-image"]} src={im4} alt="" />
              </a>
              <a className={classes["gallery__list-item"]} >
                <img className={classes["gallery__list-item-image"]} src={im5} alt="" />
              </a>
              <a className={classes["gallery__list-item"]} >
                <img className={classes["gallery__list-item-image"]} src={im6} alt="" />
              </a>
              <a className={classes["gallery__list-item"]} >
                <img className={classes["gallery__list-item-image"]} src={im7} alt="" />
              </a>
              <a className={classes["gallery__list-item"]} >
                <img className={classes["gallery__list-item-image"]} src={im8} alt="" />
              </a>
              <a className={classes["gallery__list-item"]} >
                <img className={classes["gallery__list-item-image"]} src={im9} alt="" />
              </a>
              <a className={classes["gallery__list-item"]} >
                <img className={classes["gallery__list-item-image"]} src={im10}  alt="" />
              </a>
              <a className={classes["gallery__list-item"]} >
                <img className={classes["gallery__list-item-image"]} src={im11} alt="" />
              </a>
              <a className={classes["gallery__list-item"]} href = {Pdf2} target = "_blank">
                <img className={classes["gallery__list-item-image"]} src={img11} alt="" />
              </a>
            </div>
          </div> 
      
          </section>



        <Footer></Footer>
        </>
      );
}

export default Referenzen
import React from 'react'
import "./Sidenav.css"
import { NavLink } from 'react-router-dom';
import {TfiInfinite} from "react-icons/tfi";



const Sidenav = (props) => {
    let opacityClasses = ["sidebar-container"];
    if (props.isopen) {
      opacityClasses.push("opacity-on");
    } else {
      opacityClasses.push("opacity-off");
    }
    

  return (
    <>
    <div
      className={opacityClasses.join(" ")}
      isopen={props.isopen.toString()}
      onClick={props.toggle}
    >
      <div className="sidebar-wrapper">
        <div className="sidebar-menu">
        <NavLink activeClassName="sideactiv" to="/"  className="sidebar-links" exact>
        <TfiInfinite className='icon_react'/> {props.lang === "de" ? "STARTSEITE" : "HOME"}
          </NavLink>
          <NavLink activeClassName="sideactiv"  to="/bau&interior"  className="sidebar-links">
          <TfiInfinite className='icon_react'/> {props.lang === "de" ? "TRAUMBETREUER" : "DREAM CARETAKER"}
          </NavLink>
          <NavLink activeClassName="sideactiv" to="facilitymanagement"  className="sidebar-links">
          <TfiInfinite className='icon_react'/> {props.lang === "de" ? "FACILITY MANAGEMENT" : "FACILITY MANAGEMENT"}
          </NavLink>
          <NavLink activeClassName="sideactiv"  to="hütten&chalets" className="sidebar-links">
          <TfiInfinite className='icon_react'/> {props.lang === "de" ? "CHALETS & HÜTTEN" : "CHALETS & COTTAGES"}
          </NavLink>
          <NavLink activeClassName="sideactiv" to="/team"  className="sidebar-links">
          <TfiInfinite className='icon_react'/> {props.lang === "de" ? "TEAM" : "TEAM"}
          </NavLink>
          <NavLink activeClassName="sideactiv" to="/referenzen"  className="sidebar-links">
          <TfiInfinite className='icon_react'/> {props.lang === "de" ? "REFERENZEN" : "REFERENCES"}
          </NavLink>
        
      <svg
      className='svg_react'
      xmlns="http://www.w3.org/2000/svg"
      style={{ margin: "auto" }}
      width="197"
      height="197"
      display="block"
      preserveAspectRatio="xMidYMid"
      viewBox="0 0 100 100"
    >
      <path
        fill="none"
        stroke="#fdc823"
        strokeDasharray="32.07361602783203 32.07361602783203"
        strokeLinecap="round"
        strokeWidth="2.07"
        d="M16.767 20.7C7.866 20.7 3.45 29.877 3.45 34.5s4.416 13.8 13.317 13.8c13.317 0 22.149-27.6 35.466-27.6 8.901 0 13.317 9.177 13.317 13.8s-4.416 13.8-13.317 13.8c-13.317 0-22.149-27.6-35.466-27.6z"
        style={{
          WebkitTransformOrigin: 50,
          MsTransformOrigin: 50,
          transformOrigin: 50,
        }}
      >
        <animate
          attributeName="stroke-dashoffset"
          dur="11.11111111111111s"
          keyTimes="0;1"
          repeatCount="indefinite"
          values="0;256.58892822265625"
        ></animate>
      </path>
    </svg>
        </div>
      </div>
    </div>
    </>

  )
}

export default Sidenav
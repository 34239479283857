import React from "react";
import classes from "./Nav.module.css";
import logo from "../img/logo.webp";
import { NavLink } from "react-router-dom";
const Nav = (props) => {
  return (
    <>
      <nav className={classes.navigation}>
        <ul>
          <li className={classes["navigation__list-item"]}>
            <NavLink to="/">
              <img
                className={classes["navigation__list-item-img"]}
                src={logo}
                alt="logo"
              />
            </NavLink>
          </li>
          <li className={classes["navigation__list-item"]}>
            <NavLink
              activeClassName={classes.navactiv}
              className={classes["navigation__list-item-link"]}
              to="/bau&interior"
            >
              {props.lang === "de"
                ? "TRAUMBETREUER"
                : "DREAM CARETAKER"}
            </NavLink>
          </li>

          <li className={classes["navigation__list-item"]}>
            <NavLink
              activeClassName={classes.navactiv}
              className={classes["navigation__list-item-link"]}
              to="facilitymanagement"
            >
              {props.lang === "de"
                ? "FACILITY MANAGEMENT"
                : "FACILITY MANAGEMENT"}
            </NavLink>
          </li>
          <li className={classes["navigation__list-item"]}>
            <NavLink
              activeClassName={classes.navactiv}
              className={classes["navigation__list-item-link"]}
              to="hütten&chalets"
            >
              {props.lang === "de" ? "CHALETS & HÜTTEN" : "CHALETS & COTTAGES"}
            </NavLink>
          </li>
          <li className={classes["navigation__list-item"]}>
            <NavLink
              activeClassName={classes.navactiv}
              className={classes["navigation__list-item-link"]}
              to="/team"
            >
              {props.lang === "de" ? "TEAM" : "TEAM"}
            </NavLink>
          </li>

          <li className={classes["navigation__list-item"]}>
            <NavLink
              activeClassName={classes.navactiv}
              className={classes["navigation__list-item-link"]}
              to="/referenzen"
            >
              {props.lang === "de" ? "REFERENZEN" : "REFERENCES"}
            </NavLink>
          </li>
          <li className={classes["navigation__list-item2"]}>
  <select onChange={props.langHandler} className={classes["navigation__list-item-link2"]} name="language" id="language">
    {props.lang === "de" &&
      <>
        <option value="de"> { '\uD83C\uDDE9\uD83C\uDDEA' } DE</option>
<option value="en"> { '\uD83C\uDDEC\uD83C\uDDE7' } EN</option>
      </>
    }

    {props.lang === "en" &&
      <>
        <option value="en">{ '\uD83C\uDDEC\uD83C\uDDE7' } EN</option>
        <option value="de">{ '\uD83C\uDDE9\uD83C\uDDEA' } DE</option>
      </>
    }
  </select>
</li>

          <li
            className={`${classes["navigation__list-item_icon"]} ${classes["navigation__list-item--menu"]}`}
          >
            <a
              onClick={props.toggle}
              className={classes["navigation__list-item-link_icon"]}
              href="#"
            >
              <svg className={classes.icon} viewBox="0 0 512 512">
                <style type="text/css"></style>
                <g>
                  <path
                    d="M358.06,193.61H153.94c-3.83,0-6.93-3.1-6.93-6.93s3.1-6.93,6.93-6.93h204.13c3.83,0,6.93,3.1,6.93,6.93
		S361.89,193.61,358.06,193.61z"
                  />

                  <path
                    d="M358.06,262.93H153.94c-3.83,0-6.93-3.1-6.93-6.93c0-3.83,3.1-6.93,6.93-6.93h204.13
		c3.83,0,6.93,3.1,6.93,6.93C365,259.83,361.89,262.93,358.06,262.93z"
                  />

                  <path
                    d="M358.06,332.25H153.94c-3.83,0-6.93-3.1-6.93-6.93c0-3.83,3.1-6.93,6.93-6.93h204.13
		c3.83,0,6.93,3.1,6.93,6.93C365,329.15,361.89,332.25,358.06,332.25z"
                  />

                  <path
                    d="M256,474.76c-120.62,0-218.76-98.14-218.76-218.76c0-120.63,98.14-218.76,218.76-218.76
		c120.63,0,218.76,98.14,218.76,218.76C474.76,376.62,376.62,474.76,256,474.76z M256,51.1C143.02,51.1,51.1,143.02,51.1,256
		S143.02,460.9,256,460.9S460.9,368.98,460.9,256S368.98,51.1,256,51.1z"
                  />
                </g>
              </svg>
            </a>
          </li>
        </ul>
      </nav>

      {props.children}
    </>
  );
};

export default Nav;

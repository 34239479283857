import React from "react";
import AboutUs from "./AboutUs";
import Footer from "./Footer";
import classes from "./Header.module.css";
import Nav from "./Nav";
import Partner from "./Partner";
import video from "../video/background_startsite.mov"

const Header = (props) => {
  return (
    <>

      <header className={classes.header}>

        <div className={`${classes.container} ${classes["header__container"]}`}>
        <video className={classes["story__video"]}   loop
          muted
          autoPlay
          playsInline>
            <source type="video/mp4" src={video} />
          </video> 
          <div className={classes["header__heading-container"]}>
            <h1 className={classes["header__heading-main"]}>
              All in One <br></br>Partner
            </h1>
            <p className={classes["header__heading-sub"]}>
              {props.lang === "de" ?  "Qualität | Erfahrung | Kompetenz" : "Quality | Experience | Competence"  }
            </p>
           
          </div>
        </div>
      </header>
      <section>
        <AboutUs lang={props.lang} />
      </section>
      <section>
        <Partner lang={props.lang}></Partner>
      </section>
      <section>
       <Footer lang={props.lang}></Footer>
      </section>
    </>
  );
};

export default Header;

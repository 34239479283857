import React, { useState } from 'react'
import Footer from './Footer'
import Nav from './Nav'
import "./Team.css"
import Mo from "../img/mo.jpg"

import {AiFillLinkedin} from "react-icons/ai"


const Team = (props) => {


  return (
    <>

   
    <header className="header">
    <div className="container2">

      <section className="aboutuscontainer">
      
  <div className="fifty">
    <section className="about">
      <div className="container">
        <div className="about__slideshow">
          <img className="about__img" src={Mo} alt="" />
        </div>
     
        <div className="about__text">
          <h3 className="about__heading">Mohammed Fatah</h3>
          <h5 className="about__heading-new">{props.lang === "de" ? "Geschäftsführer" : "CEO"}</h5>
         
          <p className="about__paragraph">
            <a href="tel:+436769293222" className="about__paragraph-link">
              {props.lang === "de" ? "Telefon: +43 676 929 32 22" : "Phone: +43 676 929 32 22"}
            </a>
            
          </p>
          <p className="about__paragraph">
            <a href="mailto:mf@infinity.at" className="about__paragraph-link">
              E-Mail: mf@infinity.at
            </a>
          </p>
        </div>
      </div>
    </section>
  </div>
        </section>
    </div>
   
    
    <section><Footer></Footer></section>
    </header>
  
</>
  )
}

export default Team
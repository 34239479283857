import React from 'react'
import Nav from './Nav'
import video from "../video/chaletvideo.mp4"
import img1 from "../img/chalet/1-1-1.webp"
import img2 from "../img/chalet/1-1.webp"
import img3 from "../img/chalet/2-1.webp"
import img4 from "../img/chalet/3-1.webp"
import img5 from "../img/chalet/4-1.webp"
import img6 from "../img/chalet/5-1.webp"
import img7 from "../img/chalet/6-1.webp"
import classes from "./Chalets.module.css"
import Footer from './Footer'



const Chalets = (props) => {
  return (
    <>
      <header className={classes.header}>
      <div className={classes.container}>
        
        
          <section className={classes.story}>
          <h3 className={classes["story__heading2"]}> {props.lang === "de" ? "Chalets & Hütten" : "Chalets & Cottages"}</h3>
          <video className={classes["story__video"]}   loop
          muted
          autoPlay
          playsInline>
            <source type="video/mp4" src={video} />
          </video>
        
        </section>
        <section className={classes.gallery}>
        <div className={classes["story__video-colorize"]} />
          <div className={classes["story__container"]}>
            <h3 className={classes["story__heading"]}> {props.lang === "de" ? "Chalets in Schladming" : "Chalets in Schladming"}</h3>	
            <div className={classes["story__desc"]}>
              
              {props.lang === "de" ? (
                <>
                <p>Wir sind Ihr Partner, wenn es um die Betreuung Ihrer Chalets und Hütten geht.<br /> <br /></p><p>Aktuell sind wir der betreuende Teampartner der Luxuschalets Fageralm Auszeit in Forstau. Weitere sind gerade in Rücksprache. </p>
              <p>Die Chalets verfügen über verschiedenste Luxusausstattungen beginnend bei einem großzügigen Erholungsbereich, Saunabereich mit Whirlpool und einem hochwertigen Schlafsystem.</p>
              <p>Die Chalets in Almdorf Auszeit Fageralm glänzen durch Ihre Lage inmitten der unberührten Natur und bieten einen eigenen angenehmen Aufenthalt Ihrer persönlichen Auszeit in diesem Wohlfühldomizil genießen Sie die Atmosphäre und werden von unserem Blick zum Detail überrascht.<br /> <br /> </p><p>Buchen Sie noch heute Ihren unvergesslichen Aufenthalt.</p>
                
                </>
              ):
              (
                <>

<p>We are your partner when it comes to looking after your chalets and cottages. <br /> <br /></p><p></p>  We currently look after the luxury chalets Fageralm Auszeit in Forstau as a team partner. Others are currently being discussed. 
<p>The chalets have a wide range of luxury features, starting with a spacious relaxation area, sauna area with whirlpool and a high-quality sleeping system.</p>
<p>The chalets in the Almdorf Auszeit Fageralm are characterised by their location in the midst of untouched nature and offer you a pleasant stay in this feel-good domicile. Enjoy the atmosphere and let yourself be surprised by our attention to detail. Book your unforgettable stay today.</p>

                </>
                )}

            </div>
            
          </div>
          <div className={classes["gallery__container"]}>
            <h3 className={classes["gallery__heading"]} />
            <div className={classes["gallery__list"]}>
              <a className={`${classes["gallery__list-item"]} ${classes["gallery__list-item--first"]}`} >
                <img className={classes["gallery__list-item-image"]} src={img1} alt="" />
              </a>
              <a className={classes["gallery__list-item"]}>
                <img className={classes["gallery__list-item-image"]} src={img2} alt="" />
              </a>
              <a className={classes["gallery__list-item"]} >
                <img className={classes["gallery__list-item-image"]} src={img3} alt="" />
              </a>
              <a className={classes["gallery__list-item"]} >
                <img className={classes["gallery__list-item-image"]} src={img4} alt="" />
              </a>
              <a className={classes["gallery__list-item"]} >
                <img className={classes["gallery__list-item-image"]} src={img5} alt="" />
              </a>
              <a className={classes["gallery__list-item"]} >
                <img className={classes["gallery__list-item-image"]} src={img6} alt="" />
              </a>
              <a className={classes["gallery__list-item"]} >
                <img className={classes["gallery__list-item-image"]} src={img7} alt="" />
              </a>
            </div>            
          </div>
          <div className={classes["story__book"]}>
              <a className={classes.button} href="https://almdorfauszeit.com/"> 
              {props.lang === "de" ? "JETZT BUCHEN" : "BOOK NOW"}
              </a>
            </div>
        </section>
        
      </div>
      
      <section><Footer lang={props.lang}></Footer></section>
    </header>
    </>
  )
}

export default Chalets